@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");

.team {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* MEET THE TEAM */

.heading-3 {
  width: fit-content;
  margin: 9rem auto;
  display: block;

  font-size: 50px;
  line-height: 63px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;

  text-transform: uppercase;

  background: linear-gradient(89.91deg, #63b8e6 2.9%, #735fe7 91.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* CORE 2022-2023 */

.core2022-23 {
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 40px;
  line-height: 63px;
  font-family: "Inter";
  font-style: normal;

  text-transform: uppercase;

  background: linear-gradient(89.91deg, #63b8e6 2.9%, #735fe7 91.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* EXECOM 2022-2023 */

/* .execom2022-23{
position: absolute;
width: 492px;
height: 88px;
left: 530px;
top: 4155px;


font-size: 40px;
line-height: 63px;
text-align: center;

font-family: 'Inter';
font-style: normal;
font-weight: 700;

text-transform: uppercase;

background: linear-gradient(89.91deg, #63B8E6 2.9%, #735FE7 91.86%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
} */

.execom2022-23 {
  /* width: fit-content; */
  margin: auto;
  margin-top: 15rem;
  /* margin-left: 35rem; */
  /* display: block; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 40px;
  line-height: 63px;
  font-family: "Inter";
  font-style: normal;

  text-transform: uppercase;

  background: linear-gradient(89.91deg, #63b8e6 2.9%, #735fe7 91.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.loader-container {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 100 !important;
  opacity: 0.7;
}
.loader-container img {
  width: 70px;
  height: 70px;
  margin: auto 0 auto 0;
}

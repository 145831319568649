.AUname {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  color: white;
  margin-top: 0px;
}

.AUposition {
  font-size: 15px;
  line-height: 30px;
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  color: white;
  margin-top: 5px;
}

.images {
  /* width: 180px;
    height: 180px; */
  width: 72%;
  /* height: 72%; */
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
  background: linear-gradient(89.91deg, #0c74ac 2.9%, #4331ab 91.86%);
  margin-top: 40px;
  margin-left: 33px;
  z-index: 3;
}

.bgo {
  height: 260px;
  width: 240.2px;
  background: linear-gradient(180deg, #0f163d 0%, rgba(15, 22, 61, 0) 100%);
  border-radius: 30px;
  margin-left: 5px;
  margin-top: 5px;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 0;
  /* background: linear-gradient(89.91deg, #0C74AC 2.9%, #4331AB 91.86%); */
  overflow: hidden;
  width: 83%;
  height: 0;
  border-radius: 30px;
  transition: 0.4s ease-in-out;
  z-index: 2;
}

.execomteam {
  /* height: 350px; */
  width: 250px;
  background: linear-gradient(180deg, #0f163d 0%, rgba(15, 22, 61, 0) 100%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 30px;
  /* margin-left: 30px; */
  margin-top: 75px;
  cursor: pointer;
}

.coreteam {
  /* height: 350px; */
  width: 250px;
  background: linear-gradient(180deg, #0f163d 0%, rgba(15, 22, 61, 0) 100%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 30px;
  /* margin-left: 30px; */
  margin-top: 75px;
  cursor: pointer;
}

.execomteam:hover .overlay {
  left: 0px;
  height: 100%;
  width: 250px;
  /* background: linear-gradient(89.91deg, #0C74AC 2.9%, #4331AB 91.86%); */
  background: #4623ae;
  background: -webkit-linear-gradient(top left, #4623ae, #161cea);
  background: -moz-linear-gradient(top left, #4623ae, #161cea);
  background: linear-gradient(to bottom right, #4623ae, #161cea);
  border-radius: 30px;
  z-index: -999;
}

.execomteam:hover .images {
  border-radius: 30px;
  width: 98%;
  height: 93%;
  margin-left: 2px;
  margin-top: 2px;
  transition: ease-out 0.3s;
  background: #0f163d;
}

.coreteam:hover .images {
  border-radius: 30px;
  width: 98%;
  height: 93%;
  margin-left: 2px;
  margin-top: 2px;
  transition: ease-out 0.3s;
  background: #0f163d;
}

.coreteam:hover .overlay {
  left: 0px;
  height: 100%;
  width: 250px;
  /* background: linear-gradient(89.91deg, #0C74AC 2.9%, #4331AB 91.86%); */

  background: #4623ae;
  background: -webkit-linear-gradient(top left, #4623ae, #161cea);
  background: -moz-linear-gradient(top left, #4623ae, #161cea);
  background: linear-gradient(to bottom right, #4623ae, #161cea);
  border-radius: 30px;
  z-index: -999;
}

.LImage {
  width: 45px;
  height: 45px;
  margin-top: 290px;
  margin-left: 65px;
}

.GitImage {
  width: 42px;
  height: 42px;
  margin-top: 290px;
  margin-left: 25px;
}

.coreteam:hover .AUname {
  visibility: hidden;
  transition: 0.3s;
  opacity: 0;
}

.coreteam:hover .AUposition {
  visibility: hidden;
  transition: 0.3s;
  opacity: 0;
}

.coreteam:hover .bgo {
  background: #4623ae;
  background: -webkit-linear-gradient(top left, #4623ae, #161cea);
  background: -moz-linear-gradient(top left, #4623ae, #161cea);
  background: linear-gradient(to bottom right, #4623ae, #161cea);
}

.execomteam:hover .AUname {
  visibility: hidden;
  transition: 0.3s;
  opacity: 0;
}

.execomteam:hover .AUposition {
  visibility: hidden;
  transition: 0.3s;
  opacity: 0;
}

.execomteam:hover .bgo {
  background: #4623ae;
  background: -webkit-linear-gradient(top left, #4623ae, #161cea);
  background: -moz-linear-gradient(top left, #4623ae, #161cea);
  background: linear-gradient(to bottom right, #4623ae, #161cea);
}

.GitImage:hover {
  transform: scale(0.75, 0.75);
  /* box-shadow: 3px 3px 20px 3px gray; */
}

.LImage:hover {
  transform: scale(0.75, 0.75);
  /* box-shadow: 3px 3px 20px 3px gray; */
}

@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap");

.developerContainer {
  margin-top: 5rem;
  color: #fff;
  font-family: Libre Baskerville, serif;
  margin-bottom: 7rem;
}

.developerTitleContainer {
  text-align: center;
  font-size: 1.5rem;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.developerTitle {
  margin-right: 0.8rem;
  margin-bottom: 0;
}

.typedText {
  margin-top: 0.5rem;
  font-size: 4rem;
  color: #f5b041;
}

.developerInfo {
  font-family: serif;
}

.developerInfo1 {
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  position: relative;
  z-index: 9;
  font-family: "Inter";
  font-style: italic;
  background: linear-gradient(
    89.91deg,
    rgba(99, 184, 230, 1) 2.9%,
    rgba(115, 95, 231, 1) 91.86%
  );
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@keyframes colorful {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.DPcontainer {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1em;
}
.DPcontainer .DPcard {
  position: relative;
  width: 350px;
  height: 500px;
  margin: 1em;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(40px);
  border: solid 2px transparent;
  background-clip: padding-box;
  box-shadow: 0px 10px 10px rgba(46, 54, 68, 0.03);
}
.DPcontainer .DPcard .DPcontent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.7;
  transition: 0.5s;
}
.DPcontainer .DPcard .DPcontent .DPimgContainer {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 10px solid rgba(0, 0, 0, 0.25);
}
.DPcontainer .DPcard .DPcontent .DPimgContainer .DPimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.DPcontainer .DPcard .DPcontent .DPcardContent .DPh3 {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin: 20px 0 10px;
  line-height: 1.1em;
}
.DPcontainer .DPcard .DPcontent .DPcardContent .DPh3 .DPspan {
  font-size: 12px;
  font-weight: 300;
  text-transform: initial;
}
.DPcontainer .DPcard .DPsci {
  position: absolute;
  bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.DPcontainer .DPcard .DPsci .DPli {
  margin: 0 10px;
  transform: translateY(40px);
  opacity: 0;
  transition: 0.5s;
}
.DPcontainer .DPcard .DPsci .DPli .DPa {
  font-size: 24px;
}
.DPcontainer .DPcard:hover .DPcontent {
  opacity: 1;
  transform: translateY(-20px);
}
.DPcontainer .DPcard:hover .DPsci .DPli {
  transform: translateY(0px);
  opacity: 1;
}

.DPimg2 {
  width: 24px;
}

.DPp {
  font-size: 0.8rem;
  font-style: italic;
  padding: 0 2rem;
  text-align: center;
}

.DPcontainer .DPcard:hover .DPp {
  opacity: 0;
  transition: 0.5s;
}

.particleBackground {
  z-index: -1;
}

.developer-video {
  position: relative;
  z-index: 99;
  top: -80px !important;
}

@media screen and (max-width: 450px) {
  .developerTitle {
    margin: 0;
    font-size: 1.5rem;
  }
  .typedText {
    margin: 0;
    font-size: 2rem;
  }
  .developerInfo1 {
    font-size: 1.5rem;
    margin-top: 0px;
  }
  .developerTitleContainer {
    padding-bottom: 2rem;
  }
  .developer-video {
    height: 90vh !important;
  }
}

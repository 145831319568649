.PanelDicussionSubContainer {
  color: white;
  display: flex;
  width: 60vw;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.TSHPPDConatiner1{
  margin-top: 7rem;
}

.PanelDicussionSubContainer1 {
  border: 2px solid white;
  width: 225px;
  border-radius: 20px;
  padding: 1rem;
  background-size: contain;
  margin: 0 1rem;
}

.PDSCimg {
  width: 100%;
  height: 100%;
  border-radius: 18px;
}

@media screen and (max-width: 1312px) {
  .PanelDicussionSubContainer {
    width: 80vw;
  }
  .PanelDicussionSubContainer1 {
    width: 200px;
  }
}

@media screen and (max-width: 1080px) {
  .PanelDicussionSubContainer {
    width: 90vw;
  }
  .PanelDicussionSubContainer1 {
    width: 200px;
  }
}

@media screen and (max-width: 650px) {
  .PanelDicussionSubContainer {
    width: 90vw;
    flex-direction: column;
  }
  .PanelDicussionSubContainer1 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 420px) {
  .PanelDicussionSubContainer1 {
    margin-top: 0rem;
    margin-bottom: 2rem;
  }
}

.ieeepresents {
    background: linear-gradient(
    90.38deg,
    rgba(140, 215, 255, 0.9) 13.54%,
    rgba(84, 41, 206, 0.9) 71.44%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.button{

  border: none;
  background: none;
  color: white;
  margin: auto;
  width: 161px;
  height: 39px;
  font-weight: 600;
  font-size: 14px;
  background: linear-gradient(90deg, #56b1e3 0%, #4c14bc 100%);
  border-radius: 5px;
  margin-top: 0.5rem;
  cursor: pointer;
}

.button:hover {
    background-color: #0056b3;
  }
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600;700;800;900&family=Source+Sans+Pro:wght@300;400&display=swap");
.main {
  background: radial-gradient(
    86.53% 12.69% at 49.97% 0%,
    #0e213b 0%,
    rgba(14, 32, 59, 0.997541) 0.01%,
    rgba(14, 32, 58, 0.983713) 16.91%,
    rgba(13, 31, 58, 0.97) 25.09%,
    rgba(13, 32, 58, 0.95) 39.61%,
    rgba(13, 24, 51, 0.9) 55.05%,
    rgba(6, 11, 40, 0.9) 70.49%,
    rgba(6, 11, 40, 0.95) 83.2%,
    rgba(6, 11, 40, 0.97) 91.37%,
    #060b28 100%
  );
  display: flex;
  flex-direction: column;
  padding: 5%;
}

.upper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  padding-bottom: 5%;
  z-index: 1;
}

.lower {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  padding-bottom: 5%;
  z-index: 1;
}

.left1,
.right2 {
  width: 30%;
  margin-inline: 5%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.logo_ {
  width: 100%;
  padding: 5%;
  background: url(../../assets/images/card/box.svg);
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: screen;
  opacity: 0.8;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 40px;
}

.IEEE_logo_ {
  width: 100%;
}

.leftgif {
  width: 100%;
}

.gif_right {
  width: 100%;
}

.right1,
.left2 {
  width: 90%;
  height: fit-content;
  padding: 3%;
  background: rgba(27, 37, 87, 0.8);
  border-radius: 3.125rem;
}

.heading1_,
.heading2_ {
  width: 90%;
  padding-inline: 5%;
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-size: 2.5rem;
  text-align: center;
  background: linear-gradient(90deg, #559fca 1.87%, #735fe7 75.53%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0.25rem 0.375rem 0.438 #000000;
}

.para1,
.para2 {
  width: fit-content;
  height: auto;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  text-align: justify;
  color: #ffffff;
  padding-inline: 10%;
}

@media only screen and (max-width: 950px) {
  .upper,
  .lower {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    padding-bottom: 5%;
  }

  .left1,
  .right2 {
    width: 30%;
    margin: 5%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .logo_ {
    width: 100%;
    padding: 5%;
  }

  .right1,
  .left2 {
    width: 70%;
    height: auto;
    padding: 3%;
    background: rgba(27, 37, 87, 0.8);
    border-radius: 3.125rem;
  }

  .heading1_,
  .heading2_ {
    width: 90%;
    padding-inline: 5%;
    font-weight: 700;
    font-size: 2.2rem;
  }

  .para1,
  .para2 {
    width: fit-content;
    height: auto;
    font-weight: 400;
    font-size: 1.1rem;
    padding-inline: 10%;
  }
}

@media only screen and (max-width: 790px) {
  .upper,
  .lower {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    padding-bottom: 5%;
  }

  .left1 {
    width: 35%;
    margin-right: 5%;
    margin-left: 0%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .right2 {
    width: 35%;
    margin-right: 0%;
    margin-left: 5%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .logo_ {
    width: 100%;
    padding: 5%;
  }

  .right1,
  .left2 {
    width: 70%;
    height: auto;
    padding: 2%;
    background: rgba(27, 37, 87, 0.8);
    border-radius: 3.125rem;
  }

  .heading1_,
  .heading2_ {
    width: 95%;
    padding-inline: 2.5%;
    font-weight: 700;
    font-size: 2.1rem;
  }

  .para1,
  .para2 {
    width: fit-content;
    height: auto;
    font-weight: 400;
    font-size: 1.1rem;
    padding-inline: 5%;
  }
}

@media only screen and (max-width: 670px) {
  .upper,
  .lower {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    padding-bottom: 5%;
  }

  .left1 {
    width: 35%;
    margin-right: 5%;
    margin-left: 0%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .right2 {
    width: 35%;
    margin-right: 0%;
    margin-left: 5%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .logo_ {
    width: 100%;
    padding: 5%;
  }

  .right1,
  .left2 {
    width: 70%;
    height: auto;
    padding: 2%;
    background: rgba(27, 37, 87, 0.8);
    border-radius: 3.125rem;
  }

  .heading1_,
  .heading2_ {
    width: 95%;
    padding-inline: 2.5%;
    font-weight: 700;
    font-size: 1.9rem;
  }

  .para1,
  .para2 {
    width: fit-content;
    height: auto;
    font-weight: 400;
    font-size: 1.1rem;
    padding-inline: 5%;
  }
}

@media only screen and (max-width: 450px) {
  .upper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .lower {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .leftgif {
    width: 0;
  }

  .left1 {
    width: 60%;
    margin: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 5%;
  }

  .right2 {
    width: 90%;
    margin-right: 0%;
    margin-left: 5%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .logo_ {
    width: 100%;
    padding: 5%;
  }

  .right1,
  .left2 {
    width: 90%;
    height: auto;
    padding: 5%;
    margin: auto;
    background: rgba(27, 37, 87, 0.8);
    border-radius: 3.125rem;
  }

  .heading1_,
  .heading2_ {
    width: 95%;
    padding-inline: 5%;
    font-weight: 700;
    font-size: 1.8rem;
  }

  .para1,
  .para2 {
    width: fit-content;
    height: auto;
    font-weight: 400;
    font-size: 1.1rem;
    padding-inline: 5%;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(179.82deg, #0E213B -12.5%, rgba(14, 32, 59, 0.997541) -12.5%, rgba(14, 32, 58, 0.983713) -8.22%, rgba(13, 31, 58, 0.97) -6.15%, rgba(13, 32, 58, 0.95) -2.48%, #0D1833 3.66%, rgba(6, 11, 40, 0.98) 5.34%, rgba(6, 11, 40, 0.95) 8.55%, rgba(6, 11, 40, 0.97) 10.62%, #060B28 12.81%); */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /*  */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #060b28;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #63b8e6;
  background: linear-gradient(-45deg, #63b8e6 0%, #735fe7 100%);
  background: linear-gradient(-45deg, #63b8e6 0%, #735fe7 100%);
  background: linear-gradient(135deg, #63b8e6 0%, #735fe7 100%);
  border-radius: 10px;
}

.containerTs {
  background-color: #060b28;
  /* width: 100vw !important; */
  /* width: 103vw; */
  overflow-x: hidden;
  margin-bottom: 10rem;
}
.slick-dots li.slick-active button:before {
  color: White !important;
}
.slick-dots button:before {
  color: White !important;
}
.TSWflexConainer1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
}
.TSWflexConainer2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.uptiqLogo {
  width: 250px;
}

.uptiqSponser {
  width: 300px;
  margin: auto;
}

.CSISponser {
  width: 300px;
  background-color: white;
  border-radius: 20px;
  margin: auto;
}

.uptiqPresents {
  color: white;
  font-size: 2rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  text-shadow: 0px 0px;
  margin: 0;
  padding: 0;
}
.CSIPresents {
  color: white;
  font-size: 2rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  text-shadow: 0px 0px;
  padding-top: 2rem;
}

.TechsummitSession1Container {
  /* min-height: 80vh; */
}

.TechsummitSession1 {
  color: white;
}

.TSS1b {
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 900;
}

.TechsummitSession1Heading {
  font-family: "Inter";
  /* font-style: italic; */
  font-weight: 800;
  font-size: 3rem;
  line-height: 61px;
  text-align: center;
  /* text gradient */
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: auto;
  margin-top: 5rem;
  width: fit-content;
}

.TechsummitSession1SubHeading {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 42px;
  width: 80%;
  margin: auto;
  margin-top: 2.5rem;
}

.TechsummitSession1BtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.TSS1button {
  background: linear-gradient(
    90deg,
    #5f4cd8 55.09%,
    rgba(90, 58, 211, 0.494691) 106.8%,
    rgba(84, 41, 206, 0) 106.81%,
    #5f4cd8 106.83%
  );
  padding: 1rem;
  border-radius: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 24px;
  color: #ffffff;
  margin: auto;
  cursor: pointer;
  z-index: 99;
  position: relative;
  border: 2px solid transparent;
}

.TSS1button:hover {
  border: 2px solid white;
  color: #ffffff;
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.TechsummitSession2Container {
  /* min-height: 80vh; */
}

.TechsummitSession2 {
  color: white;
}

.TechsummitSession2Heading {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-size: 3.2rem;
  line-height: 61px;
  text-align: center;
  /* text gradient */
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 76.83%,
    rgba(84, 41, 206, 0) 156.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: auto;
  margin-top: 2rem;
  /* width: fit-content; */
}

.TechsummitSession2SubHeading {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 30px;
  width: 80%;
  margin: auto;
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TechsummitSession2BtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.TSS2button {
  background: linear-gradient(
    90deg,
    #5f4cd8 55.09%,
    rgba(90, 58, 211, 0.494691) 106.8%,
    rgba(84, 41, 206, 0) 106.81%,
    #5f4cd8 106.83%
  );
  padding: 1rem 1.7rem;
  border-radius: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 24px;
  color: #ffffff;
  margin: auto;
  cursor: pointer;
  z-index: 99;
  position: relative;
  border: 2px solid transparent;
}

.TSS2button:hover {
  border: 2px solid white;
  color: #ffffff;
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.TSS2image {
  width: 35vw;
  border-radius: 20px;
}

.TSS2p {
  width: 40%;
  margin-left: 5rem;
  text-align: justify;
}

.TechsummitSession3 {
  color: white;
}

.TechsummitSession3Heading {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-size: 3.2rem;
  line-height: 61px;
  text-align: center;
  /* text gradient */
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 76.83%,
    rgba(84, 41, 206, 0) 156.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: auto;
  margin-top: 3rem;
  /* width: fit-content; */
}

.TechsummitSession3SubHeading {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 30px;
  width: 80%;
  margin: auto;
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TechsummitSession3BtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.TSS3button {
  background: linear-gradient(
    90deg,
    #5f4cd8 55.09%,
    rgba(90, 58, 211, 0.494691) 106.8%,
    rgba(84, 41, 206, 0) 106.81%,
    #5f4cd8 106.83%
  );
  padding: 1rem 1.7rem;
  border-radius: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 24px;
  color: #ffffff;
  margin: auto;
  cursor: pointer;
  /* z-index: 99; */
  position: relative;
  border: 2px solid transparent;
  z-index: 0;
}

.TSS3button:hover {
  border: 2px solid white;
  color: #ffffff;
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.TSS3image {
  width: 28vw;
  border-radius: 20px;
}

.TSS3p {
  width: 40%;
  margin-right: 9rem;
  text-align: justify;
}

.heading {
  width: 600px;
  height: 61px;

  /* margin-left: 532px !important; */
  margin-top: 250px;

  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-size: 70px;
  line-height: 61px;

  color: black;

  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.headingSpeakers {
  /* width: 379px;
  height: 61px; */
  font-family: "Inter";
  font-weight: 800;
  font-size: 3em;
  font-style: italic;
  /* line-height: 61px; */
  display: block;
  margin: 0 auto;
  text-align: center;
  /* text gradient */
  margin-top: 4rem;
  margin-bottom: 1rem;

  /* width: fit-content; */

  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 66.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  justify-content: center;
}

.subtitle {
  width: 700px;
  height: 20px;
  /* margin-left: 450px; */
  margin: 0 auto;
  display: block;

  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;

  color: #969cb6;
}

.registerNow {
  width: 212px;
  height: 70px;
  margin-left: 670px;
  margin-top: 7px;

  /* button */

  background: linear-gradient(
    90deg,
    #5f4cd8 55.09%,
    rgba(90, 58, 211, 0.494691) 106.8%,
    rgba(84, 41, 206, 0) 106.81%,
    #5f4cd8 106.83%
  );
  border-radius: 20px;
  border: 1px solid white;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 24px;

  color: #ffffff;
}

nav ul {
  list-style: none;
  color: white;
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  justify-content: space-evenly;
  padding-top: 20px;
}

nav li {
  /* display: block; */
  padding: 0px 20px;
}

nav li:hover {
  cursor: pointer;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
}

.subHeading {
  width: 80vw;
  /* height: 68px; */

  /* margin-left: 327px;
  margin-top: 20px; */

  display: block;
  margin: auto;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  padding-bottom: 35px;

  color: #969cb6;
}

.heading1 {
  /* width: 252px;
  height: 61px; */
  width: fit-content;
  font-family: "Inter";

  font-weight: 800;
  /* font-size: 50px; */
  font-size: 2.5em;
  font-style: italic;
  /* line-height: 61px; */
  text-align: center;
  /* margin-top: 53px;
  margin-left: 639px; */

  display: block;
  margin: 0 auto;
  /* text gradient */

  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.subHeading1 {
  /* width: 933px; */
  width: 80vw;
  height: 68px;

  /* margin-left: 315px;
  margin-top: 20px; */

  display: block;
  margin: 0 auto;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  color: #969cb6;
}
.companyDetails {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  gap: 2rem;
  margin-top: 2rem;
}

.cardComponent {
  /* width: 230px; */
  /* height: 250px; */
  /* margin-left: 50px;
  margin-right: 50px; */
  /* margin: 0 auto; */
  /* display: block; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* margin-top: 55px; */
  /* border: 2px solid black; */
}

.TsCompanyImage {
  background-color: #5f4cd8;
  width: 35vh;
  height: 34vh;
  border-radius: 20px;
}

.CCExpoImg {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.subDetails {
  width: 200px;
  /* height: 31px; */
  /* margin-left: 85px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  /* font-size: 24px; */
  font-size: 1.4em;
  line-height: 24px;
  color: #c7beff;
}

.subDetails2 {
  /* width: 214px; */
  width: 200px;
  /* height: 162px; */
  /* margin-left: 85px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  color: #969cb6;
}

.seeMorebtn {
  width: 212px;
  height: 70px;
  background: linear-gradient(
    90deg,
    #5f4cd8 55.09%,
    rgba(90, 58, 211, 0.494691) 106.8%,
    rgba(84, 41, 206, 0) 106.81%,
    #5f4cd8 106.83%
  );
  border-radius: 15px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 40px;

  color: #ffffff;

  cursor: pointer;
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 190px;
}

.heading2 {
  /* width: 318px;
  height: 61px; */
  font-family: "Inter";

  font-weight: 700;
  /* font-size: 50px; */
  font-size: 50px;
  font-style: italic;
  /* line-height: 61px; */

  display: block;
  margin: 0 auto;
  text-align: center;
  /* text gradient */
  margin-top: 3rem;

  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.subDetails3 {
  /* width: 600px; */
  width: 40vw;
  height: 136px;
  /* margin-top: 176px; */
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  /* font-size: 28px; */
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #969cb6;
}
.workshopContent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 10px;
  margin: 0 auto;
  height: 70vh;
}

.venueContent {
  display: flex;
  justify-content: space-evenly;
  padding: 0 10px;
  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
}

.backgroundWorkshop {
  width: 750px;
  height: 448px;
  /* margin-left: 69px; */
  background: url("../assets/images/homePage/workshop_image.jpg");
  background-size: cover;
  background-position: center center;

  border-radius: 56px;
  /* margin-top: 50px; */
  margin-right: 2rem;
}
.backgroundIdeathon {
  /* width: 730px; */
  width: 40vw;
  height: 350px;
  /* height: 448px; */
  margin-left: 69px;
  background: url("../assets/images/homePage/ideathon_new.jpg");
  background-size: cover;
  background-position: center center;

  border-radius: 56px;
  margin-top: 50px;
}

.seeMorebtn2 {
  width: 212px;
  height: 70px;
  background: linear-gradient(
    90deg,
    #5f4cd8 55.09%,
    rgba(90, 58, 211, 0.494691) 106.8%,
    rgba(84, 41, 206, 0) 106.81%,
    #5f4cd8 106.83%
  );
  border-radius: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0 auto;
  display: block;

  margin-left: 1079px;

  color: #ffffff;
}

.btnContainer2 {
  display: flex;
  align-items: center;
  width: fit-content;
  /* translate: 0 -100%; */
  /* margin-top: 190px; */
}

.heading3 {
  width: fit-content;
  height: 61px;
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-size: 50px;
  line-height: 61px;
  /* margin-top: 85px;
  margin-left: 271px; */
  margin: 0 auto;
  display: block;

  /* display: block;
  margin: 0 auto; */
  /* text gradient */

  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.seeMorebtn3 {
  width: 212px;
  height: 70px;
  background: linear-gradient(
    90deg,
    #5f4cd8 55.09%,
    rgba(90, 58, 211, 0.494691) 106.8%,
    rgba(84, 41, 206, 0) 106.81%,
    #5f4cd8 106.83%
  );
  border-radius: 15px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  margin-left: 283px;

  color: #ffffff;
}

.btnContainer3 {
  display: flex;
  justify-content: right;
  align-items: center;
  width: fit-content;

  /* margin-top: 190px; */
}

.heading4 {
  font-family: "Inter";
  /* font-style: italic; */
  font-weight: 900;
  font-size: 50px;
  line-height: 61px;
  /* identical to box height */

  /* text gradient */

  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  width: 329px;
  height: 61px;
  margin-left: 165px;
  margin-top: 50px;

  /* display: block;
  margin: 0 auto; */
}

.agendaDetails {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;

  color: #969cb6;

  width: 425px;
  height: 136px;

  margin-left: 170px;
}

.heading5 {
  font-family: "Inter";
  /* font-style: italic; */
  font-weight: 900;
  /* font-size: 50px; */
  font-size: 2em;
  font-style: italic;
  /* line-height: 61px; */
  /* identical to box height */

  /* text gradient */

  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 76.83%,
    rgba(84, 41, 206, 0) 156.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  /* width: 500px;
  height: 61px; */
  /* margin-left: 580px;
  margin-top: 50px; */
  margin: 0.5rem auto;
  /* display: block; */

  text-align: center;
  /* display: block;
  margin: 0 auto; */
  /* justify-content: center; */
}

.subDetailsSponsers {
  /* width: 984px; */
  /* height: 51px; */
  /* margin-left: 289px; */
  /* top: 4853px; */
  margin: 0 auto;
  text-align: center;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  /* font-size: 32px; */
  font-size: 1.6em;
  /* line-height: 51px; */
  /* identical to box height */
  margin-bottom: 1rem;
  color: #adc9ff;
}

.heading6 {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  /* font-size: 50px; */
  font-size: 2.5em;
  line-height: 61px;
  /* identical to box height */

  /* text gradient */

  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 50.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  width: 500px;
  height: 61px;
  margin-left: 153px;
  margin-top: 50px;

  /* display: block;
  margin: 0 auto; */
  /* justify-content: center; */
}

.collegeAddress {
  width: 497px;
  height: 116px;
  margin-left: 157px;
  top: 5472px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  color: #ffffff;
}

.domainSlider {
  width: 95vw;

  margin: 0 auto;
  display: block;
}

.domainCard {
  margin: auto;
  border: 1px solid black;
  background: linear-gradient(
    90deg,
    rgba(160, 146, 255, 0.2) 20.83%,
    rgba(160, 146, 255, 0.2) 55.09%,
    rgba(90, 58, 211, 0.0989381) 106.8%,
    rgba(84, 41, 206, 0) 106.81%,
    rgba(116, 94, 255, 0.2) 106.83%
  );
  border: 3px solid rgba(199, 190, 255, 0.1);
  border-radius: 15px;
  width: 80% !important;
  /* height: 337px; */
  padding: 2rem 0;
  display: block !important;
}

.domainCard1 {
  min-height: 337px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.domainName {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  color: #adc9ff;
  /* width: 189px;
  height: 34px; */
  /* margin-left: 110px; */
  margin-top: 0;
}

.domainName1 {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.domainInfo {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  text-align: center;
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  color: #ced1de;
  text-align: center;
  width: 80%;
  margin: 1rem auto;
  margin-bottom: 3rem;
}

.speakerInfo {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.InfoSpeaker {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.SpeakerName {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 24px;
  /* identical to box height */

  color: #adc9ff;
  width: fit-content;
  /* width: 187px;
  height: 34px; */
  /* margin-left: 22px; */
  /* margin-bottom: 6px; */
}

.designationSpeaker {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-size: 1rem;
  /* line-height: 24px; */
  /* margin-left: 22px; */
  /* margin-bottom: 6px; */
  color: #ebebeb;
  margin-top: 0;
}

.imagePerson {
  /* background-color: white; */
  /* width: 200px; */
  width: 150px;
  height: 150px;
  /* height: unset; */
  /* aspect-ratio: 1; */
  /* margin-left: 41px; */
  /* border: 1px solid white; */
  /* background: linear-gradient(
    90deg,
    rgba(160, 146, 255, 0.2) 20.83%,
    rgba(160, 146, 255, 0.2) 55.09%,
    rgba(90, 58, 211, 0.0989381) 106.8%,
    rgba(84, 41, 206, 0) 106.81%,
    rgba(116, 94, 255, 0.2) 106.83%
    ); */
  border-radius: 20px;
}

.imgPerson {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  border-radius: 20px;
}

.spekerInfo {
  display: flex;
  justify-content: space-evenly;
  /* padding: 0; */
  margin: auto;
}

.mapsImage {
  margin-left: 142px;
  /* top: 5643px; */

  /* background-color: white; */
}
.sponsSlider {
  width: 92vw;
  margin: auto;
}
.lineNav {
  background-color: white;
  height: 1px;
  width: 100%;
  margin-top: 10px;
  /* margin-bottom: 10px; */
}

.sponsImage {
  /* background-color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  /* height: 113px; */
  /* border: 1px solid black; */
  margin: 3rem 0;
  outline: 0px solid transparent;
}

.sticky {
  position: fixed;
  top: 0px;
  width: 100%;
  margin-top: 0;
  transition: all 0.3s ease-in-out;
  background-color: #060b28;
  z-index: 100;
  /* padding-bottom: 10px; */
  /* opacity: 1; */
}

.gradient {
  position: absolute;
  /* width: 690px; */
  width: 50%;
  height: 695px;
  left: 35%;
  top: 879px;

  background: linear-gradient(
    180deg,
    rgba(115, 206, 255, 0.21) 23.54%,
    rgba(61, 28, 255, 0.21) 66.68%
  );
  filter: blur(200px);
}

.gradient2 {
  position: absolute;
  /* width: 690px; */
  width: 50%;
  height: 695px;
  left: 35%;
  top: 1311px;

  background: linear-gradient(
    180deg,
    rgba(115, 206, 255, 0.28) 23.54%,
    rgba(61, 28, 255, 0.28) 66.68%
  );
  filter: blur(200px);
}

.gradient3 {
  position: absolute;
  /* width: 690px; */
  width: 50%;
  height: 695px;
  left: 35%;
  top: 1856px;

  background: linear-gradient(
    180deg,
    rgba(115, 206, 255, 0.21) 23.54%,
    rgba(61, 28, 255, 0.21) 66.68%
  );
  filter: blur(200px);
}

.gradient4 {
  position: absolute;
  /* width: 690px; */
  width: 50%;
  height: 828px;
  left: 35%;
  top: 1700px;

  background: linear-gradient(
    180deg,
    rgba(115, 206, 255, 0.21) 23.54%,
    rgba(61, 28, 255, 0.21) 66.68%
  );
  filter: blur(200px);
}

.TsHomePageSpeakerBtn {
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.TsHomePageSpeakerLink {
  text-decoration: none;
  color: #ffffff;
  z-index: 9;
  cursor: pointer;
  margin-top: 0px;
}

.TsNavbarHome {
  text-decoration: none;
  color: #ffffff;
}

.TSHPPDConatiner {
  margin-top: 4rem;
  margin-bottom: 5rem;
}

@media screen and (max-width: 1275px) {
  /* .domainName {
    margin-left: 74px;
  } */
}

@media screen and (max-width: 1200px) {
  /* .domainName {
    margin-left: 127px;
  } */
}

@media screen and (max-width: 1030px) {
  .heading1 {
    margin: auto;
    width: 301px;
    font-size: 40px;
  }

  .TechsummitSession1Heading {
    font-size: 3rem;
  }
  .TechsummitSession1SubHeading {
    font-size: 1.1rem;
    line-height: 40px;
  }
  .TSS1button {
    font-size: 1.1rem;
  }
  .TechsummitSession2Heading {
    font-size: 3rem;
  }
  .TSS2p {
    font-size: 1.1rem;
    line-height: 26px;
    /* margin-right: 2rem; */
    width: 80%;
  }
  .TSS2button {
    font-size: 1.1rem;
  }
  .TechsummitSession3Heading {
    font-size: 3rem;
  }
  .TSS3p {
    font-size: 1.1rem;
    line-height: 26px;
    margin-right: 3rem;
    width: 80%;
  }
  .TSS3button {
    font-size: 1.1rem;
  }
  .domainSlider {
    width: 87vw;
  }
  .heading1 {
    margin: auto;
    width: 210px;
    font-size: 40px;
  }
  .cardComponent {
    /* width: 200px;
    height: 200px; */
    margin: 0 auto;
  }
  .heading2 {
    /* margin: auto; */
    /* width: 257px; */
    font-size: 40px;
  }
  .subDetails3 {
    /* width: 531px; */
    width: 36vw;
    font-size: 20px;
  }
  .backgroundWorkshop {
    width: 468px;
    height: 251px;
  }
  .heading3 {
    margin: auto;
    /* width: 212px; */
    font-size: 40px;
  }
  .backgroundIdeathon {
    /* height: 339px; */
  }
  .heading4 {
    margin: auto;
    width: 645px;
    font-size: 40px;
  }
  .agendaDetails {
    font-size: 20px;
    /* margin: auto; */
    width: 366px;
    margin-left: 130px;
  }
  .heading5 {
    /* margin: auto; */
    /* width: 329px; */
    /* font-size: 40px; */
  }
  .subDetailsSponsers {
    /* font-size: 30px; */
    /* margin: auto; */
    /* width: 696px; */
  }
  .heading6 {
    margin: auto;
    /* width: 720px; */
    font-size: 40px;
    width: auto;
  }
  .collegeAddress {
    font-size: 20px;
    width: 333px;
    /* margin-left: 100px; */
    margin-left: 0;
  }

  iframe {
    width: 400px;
    height: 300px;
    margin-left: -40px;
  }

  .domainName {
    /* margin-left: 51px; */
    font-size: 1.6rem;
  }
  .SpeakerName {
    font-size: 1.3rem;
    /* width: 113px; */
  }
  .designationSpeaker {
    font-size: 0.8rem;
    /* margin-top: -1px; */
  }
  .workshopContent {
    height: 50vh;
  }
}

@media screen and (max-width: 980px) {
  .imagePerson {
    /* width: 126px; */
    /* height: 111px; */
  }
  /* .domainName {
    margin-left: 103px;
  } */
  .heading6 {
    background-color: red;
    text-align: center;
  }
  .venueContent {
    justify-content: center;
    align-items: center;
  }
  .mapsImage {
    margin: 0;
  }
  .collegeAddress {
    margin-left: 0;
    width: 500px;
  }
}

@media screen and (max-width: 900px) {
  .subHeading {
    /* width: 750px; */
    width: 90vw;
  }

  .heading1 {
    margin: auto;
    width: 206px;
    font-size: 40px;
  }
  .subHeading1 {
    /* width: 767px; */
    width: 90vw;
    margin: auto;
  }
  .cardComponent {
    /* width: 180px;
    height: 180px; */
    margin: 0 auto;
  }
  .subDetails {
    /* width: 157px; */
    font-size: 20px;
  }
  .subDetails2 {
    /* width: 178px; */
    font-size: 16px;
    line-height: 20px;
  }
  .heading2 {
    /* margin: auto; */
    /* width: 320px; */
    font-size: 40px;
  }
  .backgroundWorkshop {
    /* margin: auto; */
    height: 264px;
    /* width: 701px; */
  }
  .subDetails3 {
    /* width: 531px; */
    font-size: 18px;
    /* margin-top: 57px; */
  }
  .backgroundIdeathon {
    margin: auto;
    /* height: 264px;
    width: 701px; */
  }
  .heading3 {
    margin: auto;
    /* width: 320px; */
    font-size: 40px;
  }
  .heading4 {
    margin: auto;
    width: 645px;
    font-size: 40px;
  }
  .agendaDetails {
    font-size: 20px;
    margin: auto;
    width: 641px;
  }
  .heading5 {
    /* margin: auto; */
    /* width: 329px; */
    /* font-size: 40px; */
  }
  .subDetailsSponsers {
    /* font-size: 30px; */
    /* margin: auto; */
    /* width: 696px; */
  }
  .sponsSlider {
    width: 80%;
    margin: auto;
  }
  .heading6 {
    margin: auto;
    /* width: 540px; */
    font-size: 40px;
    text-align: center;
  }
  .collegeAddress {
    font-size: 20px;
    /* width: 333px; */
  }
  .domainName {
    /* margin: auto; */
    /* font-size: 18px; */
    /* width: 126px; */
  }
  .domainInfo {
    font-size: 0.9rem;
  }
  .SpeakerName {
    /* width: 125px; */
    /* font-size: 16px; */
    /* margin-top: 1px; */
  }

  .imagePerson {
    /* width: 180px; */
    /* height: 73px; */
    /* margin-left: 9px; */
  }
  .designationSpeaker {
    font-size: 0.9rem;
    /* margin-left: 22px; */
  }
  .seeMorebtn2 {
    width: 154px;
    height: 60px;
    margin-left: 597px;
  }
  .seeMorebtn3 {
    width: 144px;
    height: 54px;
    margin-left: 125px;
  }
}

@media screen and (max-width: 876px) {
  .imagePerson {
    /* width: 95px; */
    /* height: 89px; */
  }
  .headingSpeakers {
    font-size: 2.5rem;
  }

  .TechsummitSession1Heading {
    font-size: 2.5rem;
    margin-top: 2rem;
  }
  .TechsummitSession1SubHeading {
    font-size: 1rem;
    line-height: 30px;
  }
  .TSS1button {
    font-size: 1rem;
  }

  .TechsummitSession2Heading {
    font-size: 2.5rem;
    margin-top: 2rem;
  }
  .TSS2p {
    font-size: 1rem;
    line-height: 24px;
  }
  .TSS2button {
    font-size: 1rem;
  }
  .TechsummitSession3Heading {
    font-size: 2.5rem;
    margin-top: 2rem;
  }
  .TSS3p {
    font-size: 1rem;
    line-height: 24px;
  }
  .TSS3button {
    font-size: 1rem;
  }

  .domainCard {
    /* height: 271px; */
  }

  .domainName {
    /* margin-top: 10px; */
  }
  .SpeakerName {
    font-size: 1rem;
  }
  .designationSpeaker {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 700px) {
  nav ul {
    font-size: 16px;
  }
  .headingSpeakers {
    font-size: 2.5rem;
    margin: auto;
    /* display: block; */
    /* width: fit-content; */
    margin-top: 4rem;
  }
  .subHeading {
    /* width: 542px; */
    width: 90vw;
    font-size: 18px;
  }
  .heading1 {
    font-size: 35px;
    width: 181px;
  }
  .subHeading1 {
    width: 90vw;
    font-size: 18px;
  }
  .subDetails {
    /* width: 121px; */
    font-size: 18px;
  }
  .subDetails2 {
    /* width: 137px; */
    font-size: 14px;
    line-height: 20px;
  }
  .seeMorebtn {
    width: 132px;
    height: 45px;
  }
  .heading2 {
    /* margin: auto; */
    /* width: 227px; */
    font-size: 35px;
    margin-top: 2rem;
  }
  .backgroundWorkshop {
    /* margin: auto; */
    height: 232px;
    width: 50vw;
    border-radius: 21px;
  }
  .subDetails3 {
    /* width: 491px; */
    font-size: 16px;
    /* margin-top: 57px; */
    line-height: 20px;
  }
  .backgroundIdeathon {
    margin: auto;
    height: 232px;
    /* width: 583px; */
    border-radius: 21px;
  }
  .seeMorebtn2 {
    width: 124px;
    height: 43px;
    margin-left: 343px;
  }
  .seeMorebtn3 {
    width: 124px;
    height: 43px;
    margin-left: 103px;
  }
  .heading4 {
    width: 647px;
    font-size: 35px;
  }
  .agendaDetails {
    width: 294px;
    margin-left: 25px;
  }
  .subDetailsSponsers {
    font-size: 24px;
    /* margin: auto; */
    /* width: 480px; */
  }

  .sponsImage {
    /* height: 85px; */
  }
  .heading6 {
    margin: auto;
    /* width: 600px; */
    font-size: 35px;
  }
  .collegeAddress {
    font-size: 20px;
    width: 301px;
    /* margin-left: 52px; */
  }
  /* .mapsImage {
    width: 308px;
    height: 306px;
  } */

  iframe {
    margin-left: 10px;
  }
}

@media screen and (max-width: 645px) {
  .imagePerson {
    /* width: 131px; */
    /* height: 95px; */
    /* margin-left: 45px; */
  }
  .domainName {
    /* font-size: 22px; */
    /* width: 155px; */
    /* margin-top: 7px; */
  }

  .domainCard {
    /* height: 280px; */
  }
  .subDetailsSponsers {
    font-size: 18px;
    /* margin: auto; */
    text-align: center;
  }
  .TechsummitSession2SubHeading {
    flex-direction: column;
    margin: 2rem auto;
  }
  .TSS2image {
    width: 70%;
    margin-bottom: 2rem;
  }
  .TSS2p {
    width: 90%;
    margin: 0;
  }
  .TechsummitSession3SubHeading {
    flex-direction: column-reverse;
    margin: 2rem auto;
  }
  .TSS3image {
    width: 60%;
    margin-bottom: 2rem;
  }
  .TSS3p {
    width: 90%;
    margin: 0;
  }
}

@media screen and (max-width: 520px) {
  .subDetails {
    text-align: center;
    width: 100%;
  }
  .subDetails2 {
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .domainSlider {
    width: 80vw;
  }
}

@media screen and (max-width: 480px) {
  /* .cardComponent{
      width: 75px;
      height: 75px;
    }
    .nav ul{
      font-size: 10px;
      background-color: #8cd7ff;  
      } */

  .TechsummitSession1Heading {
    font-size: 2rem;
    margin-top: 0;
    display: block;
    width: 80%;
  }
  .TechsummitSession1SubHeading {
    font-size: 0.8rem;
    line-height: 24px;
    margin-top: 0;
    width: 90%;
    padding-left: 0px;
  }
  .TechsummitSession1SubHeading ul {
    padding-left: 10px;
  }
  .TechsummitSession1BtnContainer {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .TechsummitSession2Heading {
    font-size: 2rem;
    display: block;
    width: 80%;
  }
  .TechsummitSession2BtnContainer {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .TSS2p {
    font-size: 0.8rem;
    line-height: 18px;
    margin-top: 0;
  }
  .TSS2button {
    padding: 0.7rem 1rem;
  }
  .TechsummitSession3Heading {
    font-size: 2rem;
    display: block;
    width: 80%;
  }
  .TechsummitSession3BtnContainer {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .TSS3p {
    font-size: 0.8rem;
    line-height: 18px;
    margin-top: 0;
  }
  .TSS3button {
    padding: 0.7rem 1rem;
  }

  .headingSpeakers {
    font-size: 2rem;
    margin: 0 auto;
    /* width: fit-content; */
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
  .subHeading {
    font-size: 14px;
    margin: 0 auto;
    /* width: 286px; */
    width: 90vw;
    line-height: 20px;
  }
  .heading1 {
    font-size: 24px;
    margin: 0 auto;
    line-height: inherit;
    margin-bottom: 1.2rem;
    margin-top: 2rem;
  }
  .subHeading1 {
    font-size: 14px;
    margin: 0 auto;
    /* width: 286px; */
    width: 90vw;
    line-height: 20px;
  }
  .subDetails {
    /* width: 78px; */
    font-size: 18px;
  }
  .btnContainer {
    margin-top: 80px;
  }
  .seeMorebtn {
    width: 103px;
    height: 43px;
    font-size: 15px;
  }
  .heading2 {
    /* width: 166px; */
    font-size: 25px;
    margin-top: 1rem;
  }
  .backgroundWorkshop {
    height: 133px;
    width: 40vw;
    border-radius: 10px;
  }
  .backgroundIdeathon {
    height: 133px;
    /* width: 599px; */
    border-radius: 10px;
  }
  .subDetails3 {
    width: 40vw;
    font-size: 12px;
    /* margin-top: 65px; */
    line-height: 14px;
  }
  .heading3 {
    width: 166px;
    font-size: 25px;
  }
  .seeMorebtn2 {
    width: 85px;
    height: 32px;
    font-size: 12px;
    margin-left: 246px;
    margin-top: -42px;
  }
  .seeMorebtn3 {
    width: 85px;
    height: 33px;
    font-size: 12px;
    margin-left: 51px;
  }
  .heading4 {
    width: 308px;
    font-size: 25px;
  }
  .agendaDetails {
    font-size: 15px;
    width: 193px;
    margin-left: 35px;
    line-height: 20px;
  }
  .heading5 {
    /* width: 196px; */
    font-size: 24px;
  }
  .subDetailsSponsers {
    font-size: 18px;
    /* width: 263px; */
    line-height: 24px;
  }
  .sponsImage {
    /* height: 61px; */
  }
  .heading6 {
    /* width: 284px; */
    font-size: 25px;
  }
  .collegeAddress {
    font-size: 15px;
    width: 228px;
    /* margin-left: 49px; */
  }
  /* .mapsImage {
    width: 222px;
    height: 221px;
  } */
  nav ul {
    font-size: 10px;
    line-height: 15px;
  }
  nav li {
    padding: 0;
  }

  iframe {
    width: 250px;
    height: 200px;
    margin-left: -10px;
  }
  .containerTs {
    margin-bottom: 5rem;
  }
  .TsHomePageSpeakerBtn {
    margin-top: 0rem;
  }
  .workshopContent {
    height: 40vh;
  }
}

@media screen and (max-width: 465px) {
  .speakerInfo {
    flex-direction: column;
  }
  .domainCard {
    width: 100%;
  }
  .InfoSpeaker {
    width: 100%;
    text-align: center;
  }
  .SpeakerName {
    font-size: 1.2rem;
    line-height: 28px;
    width: 100%;
  }
  .designationSpeaker {
    width: 90%;
    margin: auto;
  }
  .uptiqSponser {
    width: 80%;
  }
  .CSISponser {
    width: 80%;
  }
  .heading5 {
    margin-top: 3rem;
  }
  .sponsImage {
    margin: 1rem 0;
  }
  .TSHPPDConatiner {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .domainCard1 {
    height: 400px;
  }
  .domainName1 {
    height: 400px;
  }
  .uptiqLogo {
    width: 200px;
  }
  .uptiqPresents {
    font-size: 1.5rem;
  }
}

.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: none;
}

.homePageGalleryTitle {
  font-size: 3rem;
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  background: linear-gradient(89.91deg, #63b8e6 2.9%, #735fe7 91.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
  translate: 0rem 5rem;
}

.pageHolder {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100vh;
  font-family: "Martel", serif;
  overflow: hidden;
}

.caption {
  font-size: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  background: linear-gradient(89.91deg, #63b8e6 2.9%, #735fe7 91.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
  margin-left: -5px;
  margin-top: 50px;
}

@media (max-width: 1220px) {
  .caption {
    font-size: 18px;
    font-family: "Inter";
    font-style: normal;
    margin-top: -225px;
  }
}

@media (max-width: 768px) {
  .caption {
    font-size: 18px;
    font-family: "Inter";
    margin-top: 50px;
  }
}

@media (max-width: 426px) {
  .pageHolder {
    height: 82vh;
  }

  .caption {
    font-size: 18px;
    font-family: "Inter";
    margin-top: 50px;
  }
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 400px;
}

.swiper-slide p {
  translate: 0% -180%;
}
@media (max-width: 768px) {
  .swiper-slide {
    width: 250px;
    height: auto;
  }
}

.swiper-button-prev {
  margin-top: 20px;
  color: linear-gradient(89.91deg, #63b8e6 2.9%, #735fe7 91.86%);
}

.swiper-button-next {
  margin-top: 20px;
  color: linear-gradient(89.91deg, #63b8e6 2.9%, #735fe7 91.86%);
}

@media (max-width: 1025px) {
  .swiper-button-prev {
    margin-top: 30px;
  }

  .swiper-button-next {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .swiper-button-prev {
    display: none;
  }

  .swiper-button-next {
    display: none;
  }
}

.swiper-slide img {
  margin-top: 160px;
  display: block;
  width: 100%;
  /* aspect-ratio: 16/9; */
  height: unset;
  /* height: 100%; */
  -o-object-fit: cover;
  object-fit: cover;
  /* transform: rotate(90deg); */
}

.HPGcontainer {
  position: relative;
  width: 100%;
  height: 60vh;
  /* background-color: #000; */
  display: block;
}

.HPGcontainer .swiper-container {
  width: 70%;
  position: absolute;
  left: 50%;
  /* bottom: -16vw; */
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 2;
  padding-bottom: 3vw;
}

@media (max-width: 768px) {
  .HPGcontainer .swiper-container {
    bottom: -20vw;
    padding-bottom: 8vw;
  }
}

.title_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  padding: 3vw 0 2vw 0;
}

@media (max-width: 768px) {
  .title_wrapper {
    padding-top: 13vw;
  }
}

.title_wrapper .reactLogo {
  width: 5vw;
  height: 5vw;
  margin-right: 1vw;
}

@media (max-width: 768px) {
  .title_wrapper .reactLogo {
    width: 25vw;
    height: 25vw;
  }
}

.title_wrapper .reactLogo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.title_wrapper .title_ {
  color: #fff;
  text-align: left;
  font-size: 2vw;
}

@media (max-width: 768px) {
  .title_wrapper .title_ {
    font-size: 7vw;
  }
}

.title_wrapper .title_ span {
  display: block;
}

.swiper-pagination-fraction {
  bottom: 0;
}

.swiper-pagination-bullet {
  width: 25px;
  height: 5px;
  border-radius: 5px;
  background: white;
}

.swiper-pagination-bullet:hover {
  background: blue;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: -10px;
}

@media screen and (max-width: 1024px) {
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -10px;
  }
}

@media screen and (max-width: 950px) {
  .homePageGalleryTitle {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 900px) {
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -10px;
  }
}

@media screen and (max-width: 790px) {
  .homePageGalleryTitle {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 40px;
  }
  .homePageGalleryTitle {
    font-size: 2.4rem;
    translate: 0% 10rem;
  }
  .pageHolder {
    translate: 0% -8rem;
  }
}

@media screen and (max-width: 441px) {
  .swiper-container-horizontal > .swiper-pagination-bullets {
    margin-top: 20px;
  }
  .homePageGalleryTitle {
    font-size: 2rem;
  }
}

@media screen and (max-width: 441px) {
  .swiper-pagination-bullet {
    width: 18px;
  }
}

@media screen and (max-width: 350px) {
  .swiper-pagination-bullet {
    width: 15px;
  }
}

@media screen and (max-width: 312px) {
  .swiper-pagination-bullet {
    width: 12px;
  }
}

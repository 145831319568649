@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");

.vision {
  margin: 0;
  padding: 0;
}

/* TeamImage */

.teamimage {
  /* position: absolute; */
  /* width: 1519px; */
  /* height: 750px; */

  width: 100%;
  /* height: 100vh; */
  top: 0px;
  object-fit: fill;
  overflow-x: hidden;
  /* z-index: -3; */
}

/* Vision */

.heading-1 {
  /* position: absolute;
  width: 278px;
  height: 73px;
  left: 648px;
  top: 1060px; */

  width: fit-content;
  margin: 6rem auto;
  display: block;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  /* line-height: 44px; */

  text-transform: uppercase;

  background: linear-gradient(89.91deg, #63b8e6 2.9%, #735fe7 91.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.heading-1b {
  font-size: 3.5rem;
  font-weight: 600;
  font-family: "Inter";
  font-style: italic;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

/* Description */

.desc {
  width: 85vw;
  margin: 4rem auto;
  display: block;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 42px;
  color: white;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .heading-1b {
    font-size: 3rem;
  }
  .desc {
    font-size: 1.5rem;
    line-height: 36px;
  }
}

@media screen and (max-width: 600px) {
  .heading-1b {
    font-size: 2.5rem;
  }
  .desc {
    font-size: 1.3rem;
    line-height: 34px;
  }
}

@media screen and (max-width: 420px) {
  .heading-1b {
    font-size: 2.2rem;
    width: 100vw;
  }
  .desc {
    font-size: 1.2rem;
    line-height: 30px;
  }
}

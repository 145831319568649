.carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.slide {
    /* height: 80%;
    width: 80%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0.1rem solid transparent;
    border-image: linear-gradient(180deg, #5A69B0 0%, #6e91be 100%);
    border-image-slice: 1;
}

.inside_row {
    display: flex;
    flex-direction: row;
}

button {
    background: transparent;
    border: none;
}


.prev_next {
    display: flex;
    flex-direction: row;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.prev_button {
    background-color: transparent;
    width: 3vw;
    height: 2vw;
    cursor: pointer;
    transform: rotate(180deg);
}

.next_button {
    background-color: transparent;
    color: #5db2e0;
    width: 3vw;
    height: 2vw;
    margin-top: 0.3vw;
    cursor: pointer;
}

.carousel_image {
    width: 30%;
    height: 15vw;
    margin-top: 6vw;
    margin-left: 4vw;
    margin-right: 3vw;
    /* margin: 3vw; */
    border-radius: 2rem;
}

.description_box {
    display: flex;
    flex-direction: column;
    width: 60%;
    /* width: 10rem; */
}

.desc_text {
    margin-left: 1vw;
    margin-right: 2vw;
    color: white;
    font-size: 1.5vw;
    text-align: center;
    /* border: 0.1rem solid transparent;
    border-image: linear-gradient(180deg, #5A69B0 0%, #6e91be 100%);
    border-image-slice: 1; */
}

.text {
    margin: 1vw;
}

.desc_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 3vw;
    text-align: center;
    margin-top: 3vw;
    margin-bottom: 2vw;
    background: linear-gradient(93.52deg, #64C5D6 11.36%, #1751E6 124.38%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.read_more {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 1.5vw;
    text-align: center;
    margin: 1.5vw;
    color: rgba(0, 103, 245, 0.62);
}

@media only screen and (max-width: 1024px) {
    .inside_row {
        display: flex;
        flex-direction: column;
    }

    .description_box {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* width: 10rem; */
    }

    .carousel_image {
        width: 30%;
        height: 15vw;
        margin-left: 20vw;
        margin-bottom: 1vw;
        /* margin-right: 15rem; */
        /* align-items: center; */
        border-radius: 2rem;
        /* align-items: center; */
    }
    
}
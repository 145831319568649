@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

.Ebody {
  background: #060b28;
  overflow-x: hidden;
  font-size: 62.5%;
  min-height: 150vh;
}

.headerEvents {
  display: flex;
  justify-content: center;
  margin-bottom: 10rem;
}

.eventHeading {
  float: right;
  width: 350px;
  padding-top: 8%;
  padding-right: 20%;
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-size: 6rem;

  background: linear-gradient(
    90.38deg,
    rgba(140, 215, 255, 0.9) 13.54%,
    rgba(84, 41, 206, 0.9) 71.44%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.EbS {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8%;
  width: 100%;
  padding-top: 8%;
  margin: auto;
  align-items: center;
}

.EbuttonStyle {
  position: relative;
  margin-bottom: 10%;
  background: linear-gradient(90deg, #56b1e3 0%, #4c14bc 100%);
  border-radius: 0.313rem;
  width: 13.75rem;
  height: 2.5rem;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;

  align-items: center;
  text-align: center;

  color: #ffffff;
}

.eventVector {
  margin: auto;
  padding-top: 5%;
  width: 536px;
  height: 390px;
}

.Econtainer {
  background-color: #060b28;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10%;
  margin-top: 5%;
  padding-top: 5%;
}

.ECard1 {
  border-radius: 3.125rem;
  width: 55%;
  padding-left: 2.5%;
  background: linear-gradient(
    180deg,
    rgba(126, 138, 237, 0.18) 0.01%,
    rgba(168, 146, 254, 0.1) 48.28%,
    rgba(168, 146, 254, 0.1) 96.35%
  );
  background-blend-mode: overlay;
  margin-right: 10%;
}

.ECard2 {
  border-radius: 3.125rem;
  width: 55%;
  padding-right: 2.5%;
  background: linear-gradient(
    180deg,
    rgba(126, 138, 237, 0.18) 0.01%,
    rgba(168, 146, 254, 0.1) 48.28%,
    rgba(168, 146, 254, 0.1) 96.35%
  );
  background-blend-mode: overlay;
  margin-left: 10%;
}

.EcardTitle1 {
  width: 75%;
  position: relative;
  margin-bottom: 5%;
  margin-top: 10%;
  /* translate: 50%; */
  margin-left: 15%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;

  align-content: right;
  color: #f5f5f5;
}

.EcardTitle2 {
  width: 75%;
  position: relative;
  margin-bottom: 5%;
  margin-top: 10%;
  /* translate: 50%; */
  margin-right: 10%;
  margin-left: 10%;
  text-align: right;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;

  align-content: right;
  color: #f5f5f5;
}
.EcardDescription1 {
  position: relative;
  padding-bottom: 10%;
  padding-right: 15%;
  padding-left: 15%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 24px;
  text-align: justify;
  color: #ffffff;
  mix-blend-mode: normal;
}

.EcardDescription2 {
  position: relative;
  text-align: right;
  padding-bottom: 10%;
  padding-left: 15%;
  padding-right: 15%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 24px;
  text-align: justify;

  color: #ffffff;
  mix-blend-mode: normal;
}

.Eanime1 {
  width: 18rem;
  height: auto;
  translate: 60% -45%;
  z-index: 9;
}

.Eanime2 {
  width: 18rem;
  height: auto;
  translate: -70% -45%;
  z-index: 9;
}

.Eanimation1 {
  border-radius: 2.5rem;
  position: relative;
  width: 100%;
  height: 100%;
}

.Eanimation2 {
  border-radius: 2.5rem;
  position: relative;
  width: 100%;
  height: 100%;
}

.Econtainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10%;
  padding-top: 5%;
}

.eventsFooter {
  padding-top: 5rem;
}

@media only screen and (max-width: 1300px) {
  .Eanime1 {
    /* width: 16rem; */
    /* height: auto; */
    translate: 35% -45%;
    z-index: 9;
  }
  .Eanime2 {
    /* width: 16rem; */
    /* height: auto; */
    translate: -40% -45%;
    z-index: 9;
  }
}

@media only screen and (max-width: 1120px) {
  .Eanime1 {
    width: 16rem;
    height: auto;
    translate: 35% -45%;
    z-index: 9;
  }

  .Eanime2 {
    width: 16rem;
    height: auto;
    translate: -40% -45%;
    z-index: 9;
  }
}

@media only screen and (max-width: 950px) {
  .eventHeading {
    /* float: right;
    width: 280px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 7.5vw;
    padding-top: 5%;
    color: white; */
    font-size: 4.5rem;

    /* background: linear-gradient(90.38deg, rgba(140, 215, 255, 0.9) 13.54%, rgba(84, 41, 206, 0.9) 71.44%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text; */
  }

  .headerEvents {
    margin-bottom: 2rem;
  }

  .eventVector {
    width: 400px;
    height: 300px;
  }

  .EbS {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8%;
    width: 100%;
    padding-top: 8%;
    margin: auto;
    align-items: center;
  }
  .EbuttonStyle {
    position: relative;
    margin-bottom: 10%;
    background: linear-gradient(90deg, #56b1e3 0%, #4c14bc 100%);
    border-radius: 0.313rem;
    width: 13.75rem;
    height: 2.5rem;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;

    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  .Eanime1 {
    width: 16rem;
    height: auto;
    translate: 30% -45%;
    z-index: 9;
  }

  .Eanime2 {
    width: 16rem;
    height: auto;
    translate: -30% -45%;
    z-index: 9;
  }

  .EcardDescription2,
  .EcardDescription1 {
    padding-bottom: 10%;
    padding-left: 15%;
    padding-right: 15%;
    line-height: 22px;
    font-weight: 400;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 790px) {
  .eventHeading {
    /* float: right;
    width: 220px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 7.5vw;
    padding-top: 10%;
    color: white; */
    font-size: 4rem;

    /* background: linear-gradient(90.38deg, rgba(140, 215, 255, 0.9) 13.54%, rgba(84, 41, 206, 0.9) 71.44%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text; */
  }

  .eventVector {
    width: 350px;
    height: 270px;
  }

  .EbS {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8%;
    width: 100%;
    padding-top: 8%;
    margin: auto;
    align-items: center;
  }
  .EbuttonStyle {
    position: relative;
    background: linear-gradient(90deg, #56b1e3 0%, #4c14bc 100%);
    border-radius: 0.313rem;
    width: 10rem;
    height: 2.5rem;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;

    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  .Eanime1 {
    width: 12rem;
    height: auto;
    translate: 30% -60%;
    margin-top: 10%;
  }

  .Eanime2 {
    width: 12rem;
    height: auto;
    translate: -30% -60%;
    margin-top: 5%;
  }

  .EcardTitle1 {
    font-weight: 700;
    font-size: 1.9rem;
    /* translate: 50%; */
    margin-left: 20%;
  }

  .EcardTitle2 {
    font-weight: 700;
    font-size: 1.9rem;
    /* translate: 50%; */
    margin-right: 15%;
  }

  .EcardDescription1 {
    padding-bottom: 10%;
    padding-left: 20%;
    padding-right: 10%;
    line-height: 22px;

    font-weight: 400;
    font-size: 0.9rem;
  }

  .EcardDescription2 {
    padding-bottom: 10%;
    padding-left: 10%;
    padding-right: 15%;
    line-height: 22px;
    font-weight: 400;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 670px) {
  .eventHeading {
    /* float: right;
    width: 250px;
    padding-right: 10%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 9vw;
    padding-top: 15%;
    padding-bottom: 5%; */
    font-size: 3rem;
    /* background: linear-gradient(90.38deg, rgba(140, 215, 255, 0.9) 13.54%, rgba(84, 41, 206, 0.9) 71.44%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; */
  }

  .eventVector {
    width: 315px;
    height: 220px;
  }

  .EbS {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8%;
    width: 100%;
    padding-top: 8%;
    margin: auto;
    align-items: center;
  }
  .EbuttonStyle {
    position: relative;
    margin-bottom: 10%;
    background: linear-gradient(90deg, #56b1e3 0%, #4c14bc 100%);
    border-radius: 0.313rem;
    width: 10rem;
    height: 2.5rem;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;

    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  .Econtainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10%;
    margin-top: 5%;
    padding-top: 5%;
  }

  .ECard1 {
    border-radius: 3.125rem;
    width: 70%;
    padding-left: 2.5%;
  }

  .ECard2 {
    border-radius: 3.125rem;
    width: 70%;
    padding-left: 2.5%;
  }

  .EcardTitle1 {
    margin-left: 20%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 1.775rem;
    align-content: right;
    color: #f5f5f5;
  }

  .EcardTitle2 {
    margin-right: 15%;
    padding-right: 10%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;

    align-content: right;
    color: #f5f5f5;
  }
  .EcardDescription1 {
    position: relative;
    padding-bottom: 10%;
    padding-right: 15%;
    padding-left: 20%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    font-size: 0.8rem;

    color: #ffffff;
    mix-blend-mode: normal;
  }

  .EcardDescription2 {
    padding-left: 10%;
    padding-right: 15%;
    line-height: 18px;
    font-size: 0.8rem;

    color: #ffffff;
    mix-blend-mode: normal;
  }

  .Eanime1 {
    width: 12rem;
    height: auto;
    translate: 30% -60%;
    z-index: 9;
  }

  .Eanime2 {
    width: 12rem;
    height: auto;
    translate: -30% -60%;
    z-index: 9;
  }

  .Eanimation1 {
    border-radius: 2.5rem;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .Eanimation2 {
    border-radius: 2.5rem;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .Econtainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10%;
    padding-top: 5%;
  }
}

@media only screen and (max-width: 570px) {
  .eventHeading {
    /* float: right;
    width: 250px;
    padding-right: 10%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 10vw;
    padding-top: 17%;
    padding-bottom: 7%; */
    /* font-size: 2.5rem; */
    /* background: linear-gradient(90.38deg, rgba(140, 215, 255, 0.9) 13.54%, rgba(84, 41, 206, 0.9) 71.44%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text; */
  }

  .eventVector {
    width: 300px;
    height: 250px;
  }

  .EbS {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8%;
    width: 100%;
    padding-top: 8%;
    margin: auto;
    align-items: center;
  }
  .EbuttonStyle {
    position: relative;
    margin-bottom: 10%;
    background: linear-gradient(90deg, #56b1e3 0%, #4c14bc 100%);
    border-radius: 0.313rem;
    width: 10rem;
    height: 2.5rem;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;

    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  .Econtainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10%;
    margin-top: 5%;
    padding-top: 5%;
  }

  .ECard1 {
    border-radius: 3.125rem;
    width: 70%;
    padding-left: 2.5%;
  }

  .ECard2 {
    border-radius: 3.125rem;
    width: 70%;
    padding-left: 2.5%;
  }

  .EcardDescription1 {
    position: relative;
    padding-bottom: 10%;
    padding-right: 15%;
    padding-left: 20%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    color: #ffffff;
    mix-blend-mode: normal;
  }

  .EcardDescription2 {
    padding-left: 10%;
    padding-right: 20%;

    font-size: 0.8rem;
    color: #ffffff;
    mix-blend-mode: normal;
  }

  .Eanime1 {
    width: 10rem;
    height: 10rem;
    translate: 30% -65%;
    z-index: 9;
  }

  .Eanime2 {
    width: 10rem;
    height: 10rem;
    height: auto;
    translate: -30% -80%;
    z-index: 9;
  }

  .Econtainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10%;
    padding-top: 5%;
  }

  .EcardTitle1 {
    margin-left: 20%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
  }

  .EcardTitle2 {
    margin-right: 10%;
    width: 70%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 450px) {
  .Ebody {
    margin-bottom: 5rem;
  }
  .eventHeading {
    float: right;
    width: 200px;
    padding-right: 10%;
    font-weight: 500;
    font-size: 2rem;
    padding-top: 25%;
    padding-bottom: 15%;

    /* background: linear-gradient(90.38deg, rgba(140, 215, 255, 0.9) 13.54%, rgba(84, 41, 206, 0.9) 71.44%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text; */
  }

  .headerEvents {
    margin-bottom: 0;
  }

  .eventVector {
    width: 100px;
    height: 100px;
  }

  .EbS {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8%;
    width: 100%;
    padding-top: 8%;
    margin: auto;
    align-items: center;
  }
  .EbuttonStyle {
    position: relative;
    margin-bottom: 10%;
    background: linear-gradient(90deg, #56b1e3 0%, #4c14bc 100%);
    border-radius: 0.313rem;
    width: 8rem;
    height: 2.2rem;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;

    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  .Econtainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10%;
    margin-top: 5%;
    padding-top: 5%;
  }

  .ECard1 {
    border-radius: 3.125rem;
    width: 90%;
    margin: auto;
    /* margin-inline: 10%; */
    padding-top: 20%;
    padding-bottom: 10%;
    margin-bottom: 0;
    margin-top: 0;
  }

  .EcardTitle1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    color: #f5f5f5;
    padding-bottom: 10%;
    text-align: center;
    margin-left: 0;

    width: 100%;
  }

  .EcardDescription1 {
    position: relative;
    text-align: justify;
    padding-bottom: 0%;
    padding-right: 5%;
    padding-left: 5%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    color: #ffffff;
    mix-blend-mode: normal;
  }

  .Eanime1 {
    width: 10rem;
    height: 10rem;
    margin-left: auto;
    margin-right: auto;
    translate: 0% 40%;
    z-index: 9;
    margin-top: -3rem;
  }

  .Econtainer {
    display: block;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10%;
    padding-top: 5%;
  }
}

@media only screen and (max-width: 360px) {
  .eventHeading {
    width: 200px;
    font-size: 2rem;
    padding-top: 40%;
    padding-bottom: 25%;
    padding-left: 10%;
    /* background: linear-gradient(90.38deg, rgba(140, 215, 255, 0.9) 13.54%, rgba(84, 41, 206, 0.9) 71.44%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text; */
  }

  .EbS {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8%;
    width: 100%;
    padding-top: 8%;
    margin: auto;
    align-items: center;
  }
  .EbuttonStyle {
    position: relative;
    margin-bottom: 10%;
    background: linear-gradient(90deg, #56b1e3 0%, #4c14bc 100%);
    border-radius: 0.313rem;
    width: 7rem;
    height: 2rem;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;

    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  .Econtainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10%;
    margin-top: 5%;
    padding-top: 5%;
  }

  .ECard1 {
    border-radius: 3.125rem;
    width: 90%;
    margin-inline: 10%;
    padding-top: 20%;
    padding-bottom: 5%;
    margin-bottom: 0;
    margin-top: 0;
    margin: 0 auto;
  }

  .EcardTitle1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    color: #f5f5f5;
    padding-bottom: 10%;
    text-align: center;
    margin-left: 0;

    width: 100%;
  }

  .EcardDescription1 {
    position: relative;
    text-align: justify;
    padding-bottom: 5%;
    padding-right: 5%;
    padding-left: 5%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;

    color: #ffffff;
    mix-blend-mode: normal;
  }

  .Eanime1 {
    width: 10rem;
    height: 10rem;
    margin-left: auto;
    margin-right: auto;
    translate: 0% 40%;
    z-index: 9;
    margin-top: -3rem;
  }

  .Econtainer {
    display: block;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10%;
    padding-top: 5%;
  }
}

/* 




@media screen {
  
}

.Card2 {
  font-size: 1rem;
  /* padding-left: 15px; */
/* margin-left: 10%; */
/* border-radius: 3.125rem;
  width: 40rem; */
/* padding-left: 0.313rem; */
/* padding-right: 8%;
  background: linear-gradient(
    180deg,
    rgba(126, 138, 237, 0.18) 0.01%,
    rgba(168, 146, 254, 0.1) 48.28%,
    rgba(168, 146, 254, 0.1) 96.35%
  );
  background-blend-mode: overlay;
}

.gradientContainer1{
  overflow: hidden;
  width: 50%;
  position: absolute;
  width: 52.969rem;
  height: 63.375rem;
  translate: -50%;

}

.gradient1 {
  /* position: absolute; */
/* background: linear-gradient(
    180deg,
    rgba(86, 177, 227, 0.35) 63.81%,
    rgba(42, 7, 247, 0.35) 85.46%
  );
  filter: blur(200px);
  border-radius: 60%;
  z-index: 0;

}
.gradientContainer2{
  overflow: hidden;
  width: 50%;
  position: absolute;
  width: 52.969rem;
  height: 63.375rem; 
  translate: -50%;
 
}

.gradient2 { */
/* position: absolute; */

/* left: -30.875rem;
  top: 153.938rem; */
/* border-radius: 60%;
  z-index: 0;

  background: linear-gradient(
    180deg,
    rgba(86, 177, 227, 0.35) 19.13%,
    rgba(61, 28, 255, 0.35) 71.25%
  );
  filter: blur(200px);
}

@media screen and (max-width:600px){
  .Card1{
    width: 60vw ;
  }
  
}  */

.IdeathonContainer {
  min-height: 100vh;
  color: white;
  margin-bottom: 8rem;
}

.IdeathonSession1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 10rem; */
  min-height: 80vh;
}

.IdeathonSession1Title {
  font-size: 4rem;
  width: 100%;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0 auto;
}

.IdeathonSession1SubTitle {
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  /* margin-top: 2rem; */
  margin-bottom: 6rem;
  color: white;
  font-weight: bold;
}

.IdeathonRegistration {
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  padding: 1rem;
  border-radius: 10px;
}

.IdeathonRegistrationLink {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
}

.IdeathonSession2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.IdeathonSession2Title {
  font-size: 3rem;
  width: 100%;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0 auto;
}

.TSdomainContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin-top: 5rem;
}

.TSIflexContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.TSdomain {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* margin-top: 1rem; */
  width: 100%;
  padding: 2rem;
  border-radius: 20px;
  background-color: #17204e;
  font-size: 1.2rem;
  margin-bottom: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TSIflexContainer2 {
  translate: 0 100px;
}

.IdeathonSession3 {
  margin-top: 5rem;
}

.IdeathonSession3Title {
  font-size: 3rem;
  width: 100%;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0 auto;
  margin-bottom: 3rem;
}

.TSRcontainer1 {
  width: fit-content;
  padding-left: 4rem;
}

.TSRcontainer2 {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TSRteamSize1 {
  font-size: 2rem;
  font-weight: bold;
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
}

.TSRteamSize2 {
  font-size: 1.5rem;
  margin-left: 0.5rem;
}

.TSRcontainer3 {
  margin-top: 1rem;
}

.TSregistrationFlexContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
}

.TSRimageContainer {
  width: 30vw;
  /* height: 30vw; */
  margin-right: 4rem;
}

.TSRimage {
  width: 100%;
  /* height: 100%; */
  border-radius: 20px;
}

.IdeathonSession4Title {
  margin-top: 5rem;
}

.TSIScard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: 2px solid white;
  border-radius: 20px;
  width: fit-content;
  margin: auto;
}

.TSISlogoContainer {
  height: 100px;
  background-color: white;
  border-radius: 20px;
  margin-right: 1.5rem;
}

.TSISLogo {
  width: 100%;
  height: 100%;
}

.TSIScardInfoContainer {
  width: 60vw;
  font-size: 1.2rem;
}

.IdeathonSession1LogoImage {
  border-radius: 20px;
  width: 30rem;
  height: 7rem;
  object-fit: cover;
}

.IdeathonSession1Logo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ISLIpresents {
  font-size: 1.2rem;
  /* font-weight: bold; */
  margin: 0;
  margin-top: 0.5rem;
}

.IdeathonEligibilityUi {
  width: 80%;
  margin: auto;
  font-size: 1.3rem;
}

.TSIb {
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
}

.TSIviewMore {
  display: flex;
}

.TSIviewMoreLink {
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: black;
  font-size: 2rem;
  width: fit-content;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  margin: auto;
  border: 2px solid transparent;
}

.TSIviewMoreLink:hover {
  color: white;
  border: 2px solid white;
}

@media screen and (max-width: 1000px) {
  .TSdomainContainer {
    flex-direction: column;
  }
  .TSdomain {
    height: fit-content;
    margin: 20px 0;
  }
  .TSIflexContainer1 {
    margin-bottom: 5rem;
    width: 50vw;
    min-width: 400px;
  }
  .TSIflexContainer2 {
    width: 50vw;
    min-width: 400px;
    translate: 0 0;
    margin-top: 0;
  }
  .TSRteamSize1 {
    width: fit-content;
  }
  .IdeathonSession2 {
    margin-bottom: 20rem;
  }
  .TSRcontainer1 {
    padding: 0;
  }
}

@media screen and (max-width: 775px) {
  .TSregistrationFlexContainer {
    flex-direction: column-reverse;
  }
  .TSRimage {
    width: 30vw;
    float: right;
  }
  .TSRimageContainer {
    width: 100%;
  }
  .TSRcontainer1 {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .TSIScard {
    flex-direction: column;
  }
  .TSIScardInfoContainer {
    font-size: 1rem;
  }
  .TSISlogoContainer {
    margin-right: 0rem;
  }
  .IdeathonSession3Title {
    font-size: 2rem;
  }
  .TSIflexContainer {
    margin-bottom: 0rem;
  }
  .IdeathonEligibilityUi {
    font-size: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .TSdomain {
    width: 80vw;
  }
  .IdeathonContainer {
    overflow-x: hidden;
  }
  .TSRteamSize1 {
    font-size: 1.5rem;
  }
  .TSRteamSize2 {
    font-size: 1rem;
  }
  .IdeathonSession1Title {
    font-size: 2rem;
  }
  .IdeathonSession1SubTitle {
    font-size: 1.5rem;
  }
  .IdeathonSession2Title {
    font-size: 2rem;
  }
  .TSdomain {
    text-align: center;
  }
  .IdeathonSession2 {
    margin-bottom: 0;
  }
  .IdeathonSession4Title {
    margin-top: 2rem;
    font-size: 2rem;
  }
  .IdeathonContainer {
    margin-bottom: 7rem;
  }
  .TSIflexContainer2 {
    margin-top: 0rem;
  }
  .IdeathonSession1LogoImage {
    width: 20rem;
  }
}

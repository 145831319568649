.NavBar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  z-index: 10;
  /* right: 20px; */
  top: 0rem;
  color: white;
  background-color: #060b28;
  /* background: linear-gradient(180deg, rgba(116, 95, 244, 0.86) 0%, rgba(43, 50, 178, 0.86) 100%); */
  /* background: radial-gradient(86.53% 12.69% at 49.97% 0%, #0E213B 0%, rgba(14, 32, 59, 0.997541) 0.01%, rgba(14, 32, 58, 0.983713) 16.91%, rgba(13, 31, 58, 0.97) 25.09%, rgba(13, 32, 58, 0.95) 39.61%, rgba(13, 24, 51, 0.9) 55.05%, rgba(6, 11, 40, 0.9) 70.49%, rgba(6, 11, 40, 0.95) 83.2%, rgba(6, 11, 40, 0.97) 91.37%, #060B28 100%); */
}

.nav-black {
  display: none !important;
}

.UnderLine_container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.UnderLine {
  margin-right: 1rem;
  margin-top: 1rem;
  height: 0.19vw;

  background: linear-gradient(
    90.01deg,
    rgba(181, 87, 214, 0.75) 5.95%,
    rgba(111, 203, 253, 0.44) 58.68%
  );

  /* margin-bottom: 3.125vw; */
  /* text-align: center; */
  width: 95%;
}

.Logo {
  position: fixed;
  top: -0.7rem;
  left: -0.5rem;
  /* width: 140px; */
  height: 80px;
  z-index: 10;
}

.active {
  color: rgb(116, 146, 255) !important;
}

.Logo:hover {
  cursor: pointer;
}

.nav-btn {
  /* padding: 50rem; */
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  display: none;
  opacity: 0;
  font-size: 1.8rem;
  /* size: 100vw; */
  /* width: 50vw;
    height: 50vw; */
}

.noclass {
  display: none;
}

.navList {
  /* position: fixed;
    left: 60rem; */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 650;
  font-size: 1.5rem;
  height: 100%;
  /* line-height: 3rem; */
  /* display: flex; */
  /* top: -0.5rem; */
  /* background-color: red; */

  /* -webkit-animation: bounce ;
    animation: bounce 1s ; */
}

.navbarDesktop {
  display: flex;
}

.col_align_nav {
  display: flex;
  flex-direction: column;
}

.navList a {
  align-items: center;
  /* left: 1000rem; */
  margin-right: 3rem;
  /* top:10rem; */
  color: rgb(255, 255, 255);
  text-decoration: none;
  text-shadow: #0b0b0e;
}

.navList a:hover {
  /* background: linear-gradient(90deg, #6FCBFD 0%, #6C5ADB 100%); */
  background: linear-gradient(90deg, #6fcbfd 0%, #6c5adb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.Elements {
  /* border-bottom: 4px solid; */
  /* border-bottom-style: linear-gradient(90.01deg, rgba(181, 87, 214, 0.75) 5.95%, rgba(111, 203, 253, 0.44) 58.68%); */
  /* border-color: #6C5ADB; */
  /* border-right: 1px; */
  /* display: table-column; */
  /* top : 2rem; */
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.border {
  border-top: 4px solid white;
}

.hideNav {
  display: flex;
}

@media only screen and (max-width: 1024px) {
  .nav-btn {
    display: block;
    position: fixed;
    margin-top: -0.4vw;
    left: 90%;
    opacity: 1;
    /* size: 100rem; */
    width: 6vw;

    color: rgb(248, 247, 249);
  }

  .navList a {
    margin-right: 0rem;
    align-items: center;
    /* left: 1000rem; */
    /* margin-right: 3rem; */
    /* top:10rem; */
    /* font: 1vw; */
    /* size: 10%; */
    color: rgb(253, 253, 253);
    text-decoration: none;
    text-shadow: #0b0b0e;
  }

  .hideNav {
    display: none !important;
  }

  /* .showNav{
        font: 10vw;
        text-align: center;
    } */

  /* .link_ele{
        display: flex;
        text-align: center;
        font-size: 100%;
    } */

  .navList {
    /* display: none !important; */
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; */
    gap: 2rem;
    background: radial-gradient(
      114.07% 114.07% at 9.75% 14.48%,
      #0c102d 0%,
      #4b2278 100%
    );
    /* background:linear-gradient(156.15deg, #0C102D 31.92%, #653E90 116.78%);; */
    /* background-color: beige; */
    /* background-image: url(); */
    transition: 20s;
    /* transform: translateY(-100vh); */
  }

  .navbarDesktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .NavBar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 6vw;
    z-index: 10;
    /* right: 20px; */
    top: 0rem;
    color: white;
    background-color: #060b28;
    /* background: linear-gradient(180deg, rgba(116, 95, 244, 0.86) 0%, rgba(43, 50, 178, 0.86) 100%); */
    /* background: radial-gradient(86.53% 12.69% at 49.97% 0%, #0E213B 0%, rgba(14, 32, 59, 0.997541) 0.01%, rgba(14, 32, 58, 0.983713) 16.91%, rgba(13, 31, 58, 0.97) 25.09%, rgba(13, 32, 58, 0.95) 39.61%, rgba(13, 24, 51, 0.9) 55.05%, rgba(6, 11, 40, 0.9) 70.49%, rgba(6, 11, 40, 0.95) 83.2%, rgba(6, 11, 40, 0.97) 91.37%, #060B28 100%); */
  }

  .Logo {
    position: fixed;
    top: -2.5vw;
    left: -0.5rem;
    width: 18vw;
    height: 10vw;
    z-index: 10;
  }
}

@media only screen and (max-width: 600px) {
  .nav-btn {
    display: block;
    position: fixed;
    margin-top: -0.6vw;
    left: 90%;
    opacity: 1;
    /* size: 100rem; */
    width: 6vw;

    color: rgb(248, 247, 249);
  }
}

@media only screen and (max-width: 500px) {
  .nav-btn {
    display: block;
    position: fixed;
    margin-top: 0vh;
    left: 85%;
    opacity: 1;
    /* size: 100rem; */
    width: 7vh;
    /* height: 8vh; */

    color: rgb(248, 247, 249);
  }
  .Logo {
    height: 10vh;
    width: auto;
  }
  .NavBar {
    height: 8vh;
  }
}

@media only screen and (max-width: 400px) {
  .nav-btn {
    display: block;
    position: fixed;
    /* margin-top: -1.3vw; */
    /* left: 90%; */
    opacity: 1;
    /* size: 100rem; */
    /* width: 6vw; */

    color: rgb(248, 247, 249);
  }
}

@media only screen and (max-width: 350px) {
  .nav-btn {
    display: block;
    position: fixed;
    /* margin-top: -1.8vw; */
    /* left: 90%; */
    opacity: 1;
    /* size: 100rem; */
    /* width: 6vw; */

    color: rgb(248, 247, 249);
  }
}

/* animation for bounce */

@-webkit-keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
  }

  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
  }

  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
    transform: scale3d(1, 1.2, 1);
  }

  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
    transform: scale3d(1, 0.85, 1);
  }

  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
    transform: scale3d(1, 1.05, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    opacity: 0;
  }

  25% {
    -webkit-transform: translate3d(0, 10px, 0);
  }

  50% {
    -webkit-transform: translate3d(0, -6px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 2px, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
    opacity: 0;
  }

  25% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  50% {
    -webkit-transform: translate3d(0, -6px, 0);
    transform: translate3d(0, -6px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 2px, 0);
    transform: translate3d(0, 2px, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.dropdown {
  position: absolute;
  top: 3rem;
  right: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #0c102d;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  transform: translateY(-100%);
  border: 2px solid white;
  border-radius: 20px;
  height: fit-content;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* width: 12rem; */
}

.dropdown-show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-hide {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100%);
}

.yourAccArrow {
  width: 15px;
  margin-left: 15px;
  padding-bottom: 2.5px;
}

.dropDownOption {
  padding-left: 3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.DomainMobileCircle {
  width: 100%;
  height: 150px;
  overflow: hidden;
  position: relative;
  padding-top: 2rem;
}

.DMtitle {
  font-size: 3rem;
  font-style: italic;
  font-family: "Inter";
  text-align: center;
  position: relative;
  background: linear-gradient(90deg, #559fca 1.87%, #735fe7 75.53%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.DomainMobileCard {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.DomainMobileInfo {
  font-size: 1.1rem;
  text-align: justify;
  line-height: 1.5rem;
  margin: 0;
}

.DomainMobileContentContainer {
  width: 90%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: rgba(27, 37, 87, 0.8);
  padding: 1rem 0;
  margin: auto;
  border-radius: 20px;
}
.DMbtn {
  height: 20px;
}

.DMbtnImg {
  width: 20px;
  height: 15px;
}

.DMprevBtn {
  transform: rotate(90deg);
  margin-left: 20px;
}

.DMnextBtn {
  transform: rotate(-90deg);
  margin-right: 20px;
}

#circleContainerMobile {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 10px auto;
  border-radius: 100%;
  transition: all ease 1s;
  border: 3px solid #735fe7;
  /* translate: -6%; */
}

.DCMitem {
  width: 70px;
  border-radius: 50%;
  transform: rotateZ(-80deg);
}

.DCMsrow1,
.DCMsrow2,
.DCMsrow3,
.DCMsrow4 {
  width: 350px;
  translate: -30px;
  /* width: 25rem; */
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 35%;
}

.DCMsrow1 {
  transform: rotateZ(0deg);
}
.DCMsrow2 {
  transform: rotateZ(45deg);
}
.DCMsrow3 {
  transform: rotateZ(90deg);
}
.DCMsrow4 {
  transform: rotateZ(135deg);
}

.DMCcoding {
  transform: rotate(90deg);
  translate: 10px -10px;
}

.DMCcyber {
  transform: rotate(90deg);
  translate: 25px -10px;
  width: 90px;
  height: 80px;
}

.DMCiot {
  transform: rotate(90deg);
  translate: 20px;
}

.DMCresearch {
  transform: rotate(90deg);
  translate: 20px 5px;
  width: 90px;
  height: 80px;
}
.DMCweb {
  transform: rotate(90deg);
  translate: -20px -5px;
  width: 90px;
  height: 80px;
}

.DMCai {
  translate: -20px 10px;
  width: 90px;
  height: 80px;
}

.DMCapp {
  transform: rotate(270deg);
  translate: -20px 10px;
  width: 90px;
  height: 80px;
}

.DMCblockchain {
  transform: rotate(270deg);
  translate: -25px -0px;
  width: 100px;
  height: 90px;
}

.DomainNameTitle {
  background: linear-gradient(90deg, #559fca 1.87%, #735fe7 75.53%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.5rem;
  text-align: center;
  font-family: "Inter";
  font-style: italic;
}

@media screen and (max-width: 670px) {
  .DMtitle {
    font-size: 2.3rem;
  }
  .DomainNameTitle {
    font-size: 2rem;
  }
  .DomainMobileInfo {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 600px) {
  .DomainMobileContainer {
    display: block;
  }
}

@media screen and (max-width: 400px) {
  .DomainMobileCircle {
    height: 80px;
    padding-top: 2rem;
  }

  .DomainMobileContentContainer {
    width: 95%;
  }

  .DomainMobileCard {
    width: 90%;
    padding: 0;
  }

  .DMprevBtn {
    margin: 0;
  }
  .DMbtnImg {
    width: 15px;
    height: 10px;
  }
  .DMnextBtn {
    margin: 0;
  }

  #circleContainerMobile {
    width: 250px;
    height: 250px;
  }
  .DCMsrow1,
  .DCMsrow2,
  .DCMsrow3,
  .DCMsrow4 {
    width: 300px;
    translate: -30px;
    top: 35%;
  }

  .DMCcoding {
    translate: 10px -15px;
    width: 60px;
    height: 50px;
  }

  .DMCcyber {
    translate: 10px 0px;
    width: 60px;
    height: 50px;
  }

  .DMCiot {
    translate: 5px;
    width: 50px;
    height: 50px;
  }

  .DMCresearch {
    translate: 15px 5px;
    width: 60px;
    height: 50px;
  }
  .DMCweb {
    transform: rotate(90deg);
    translate: -10px -0px;
    width: 60px;
    height: 50px;
  }

  .DMCai {
    translate: 0px 0px;
    width: 60px;
    height: 60px;
  }

  .DMCapp {
    transform: rotate(270deg);
    translate: -5px 10px;
    width: 60px;
    height: 60px;
  }

  .DMCblockchain {
    transform: rotate(270deg);
    translate: -10px 10px;
    width: 80px;
    height: 70px;
  }
}

.agenda {
  margin: 1rem auto;
  width: 100%;
  /* min-height: 87vh; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* background-color: yellow; */
  padding-top: 4rem;
}

.agendaContent {
  width: 50vw;
  /* padding-bottom: 10rem; */
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.agendaHeader {
  width: fit-content;
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-size: 20px;
  line-height: 61px;
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.agendaHeader p {
  font-size: 40px;
  line-height: 10px;
}

.agendaBody {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  width: 35vw;
  text-align: center;
  color: #969cb6;
}

.timelineContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline {
  /* margin: 4rem; */
  width: 100%;
}

.TLevent {
  color: white;
  display: flex;
  margin-bottom: 3rem;
}

.TLeventTime {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #dad5ff;
  width: 7rem;
  background: linear-gradient(
    90deg,
    #5f4cd8 55.09%,
    rgba(90, 58, 211, 0.494691) 106.8%,
    rgba(84, 41, 206, 0) 106.81%,
    #5f4cd8 106.83%
  );
  border-radius: 15px;
  padding: 0.5rem;
  margin-right: 2rem;
  height: 1.7rem;
}

.TLeventTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #c7beff;
  margin-top: 0.6rem;
  margin-bottom: 0.2rem;
  z-index: 9;
}

.TLeventDescription {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #969cb6;
  width: 25vw;
}

.TLflexContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.timelineLine {
  border-radius: 20px;
  z-index: 0;
}

.TSAbtn {
  padding: 1rem 2rem;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  color: white;
  font-size: 1rem;
  background: linear-gradient(
    90deg,
    #5f4cd8 55.09%,
    rgba(90, 58, 211, 0.494691) 106.8%,
    rgba(84, 41, 206, 0) 106.81%,
    #5f4cd8 106.83%
  );
  border-radius: 15px;
  cursor: pointer;
}

.AgendaShowDate {
  width: fit-content;
  margin: 0;
  margin-top: 5rem;
  padding: 1rem 2rem;
}

.TSAbtnActive {
  background: none;
  border: 2px solid #5f4cd8;
  border-radius: 15px;
  outline: none;
}

@media screen and (max-width: 1030px) {
  .agendaHeader p {
    font-size: 2.5rem;
  }
  .agendaBody {
    font-size: 1rem;
    line-height: 1.7rem;
  }
  .TSAbtn {
    padding: 0.7rem 1.5rem;
    color: white;
    font-size: 1rem;
    border-radius: 12px;
  }
  .TLeventTitle {
    font-size: 1.1rem;
  }
  .TLeventDescription {
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
  .TLeventTime {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 873px) {
  .agendaHeader p {
    font-size: 2.4rem;
  }
  .agendaBody {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
  .TSAbtn {
    padding: 0.7rem 1.3rem;
    font-size: 0.9rem;
  }
  .TLeventTitle {
    font-size: 1rem;
    width: 95%;
  }
  .TLeventDescription {
    font-size: 0.9rem;
    line-height: 1.3rem;
    width: 95%;
  }
}

@media screen and (max-width: 700px) {
  .agenda {
    flex-direction: column;
    padding-top: 0rem;
  }
  .agendaContent {
    width: 100%;
    padding-bottom: 5rem;
  }
  .agendaHeader p {
    font-size: 2.5rem;
    line-height: 10px;
  }
  .agendaBody {
    font-size: 1.2rem;
    line-height: 1.9rem;
  }
  .TSAbtn {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
  .agendaBody {
    width: 80vw;
  }
  .TLeventTime {
    font-size: 0.8rem;
  }
  .TLflexContainer {
    width: 80vw;
  }
  .timelineContainer {
    width: 80%;
    margin: auto;
  }
  .TLevent {
    width: 100%;
  }
  .TLeventContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .TLeventTitle {
    font-size: 16px;
  }
  .TLeventDescription {
    width: 100%;
    font-size: 14px;
  }
  .AgendaShowDate {
    margin-top: 3rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .agendaContent {
    padding-bottom: 3rem;
  }
  .agenda {
    padding-top: 0rem;
  }
  .agendaHeader p {
    font-size: 24px;
    line-height: 10px;
  }
  .agendaBody {
    font-size: 16px;
  }
  .TLeventTime {
    font-size: 12px;
    /* width: 10rem; */
  }
  .TLflexContainer {
    width: 25vw;
  }
  .TLevent {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .TLeventTitle {
    font-size: 16px;
    width: 100%;
  }
  .TLeventDescription {
    width: 43vw;
    font-size: 14px;
  }
  .AgendaShowDate {
    margin-top: 3rem;
    padding: 0.7rem 1.6rem;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 420px) {
  .agendaHeader p {
    font-size: 1.8rem;
  }
  .agendaBody {
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
  .TSAbtnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .TSAbtn {
    padding: 0.6rem 1rem;
    color: white;
    font-size: 0.9rem;
    border-radius: 12px;
  }
  .timelineContainer {
    width: 95%;
    margin: auto;
  }
  .TLflexContainer {
    width: 100%;
  }
  .TLeventTitle {
    font-size: 0.9rem;
    width: 100%;
  }
  .TLeventDescription {
    font-size: 0.9rem;
    line-height: 1.3rem;
    width: 100%;
  }
  .TLeventTime {
    font-size: 0.9rem;
  }
}

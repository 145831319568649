/*.space {
  height: 25.5vh;
}*/

.footer_parent {
  position: relative;
  padding-top: 0%;
  /* background-color: #060b28; */
}

.footerbg {
  position: absolute;
  /* left: 0;
  bottom: 0; */
  height: 11rem;
  width: 100%;
  background-image: url(../../assets/images/footer/Polygon2.svg);
  background-color: #060b28;
  /* background: linear-gradient(92.87deg, rgba(90, 183, 236, 0.72) 8.6%, rgba(104, 112, 224, 0.54) 43.51%, rgba(112, 82, 223, 0.72) 61.73%); */
}

.footer1 {
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(180deg, #0e143a 1.39%, #060b28 100%);
  border: 0.5px solid rgba(6, 11, 40, 0.25);
  box-shadow: 8px 8px 80px 5px rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  width: 80%;
  margin-inline: 7.5%;

  margin-bottom: 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 1.5% 2.5%;
}

.follow_us {
  width: 30%;
  margin: auto;
  align-items: center;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-inline: 5%;
}

.linkedinLogo,
.facebookLogo,
.instagramLogo,
.youtubeLogo {
  width: 3.2rem;
  /* background-color: grey; */
  /* border-radius: 50%; */
  margin-inline: 2%;
}

.facebookLogo {
  width: 2.2rem;
  translate: 0 -0.5rem;
}

.footerHeading1 {
  color: white;
  font-size: 1.2rem;
}

.logo {
  width: 20%;
  margin: auto;
  align-items: center;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-inline: 10%;
}

.IEEE_logo {
  width: 10rem;
  height: auto;
}

.FooterlastDiv {
  /* height: 20vh; */
  width: 100%;
  background-color: #060b28;
  z-index: 9999;
  color: white;
  text-align: center;
  position: absolute;
  bottom: -15rem;
  left: 0;
}

.footerCopyrightLine {
  font-size: 1rem;
}

.footerDevelopersLine {
  font-size: 0.8rem;
}

.developersLastLink {
  color: #00b0ff;
  margin-left: 0.5rem;
  text-decoration: none;
}

.developersLastLink:hover {
  color: #00b0ff;
  text-decoration: underline;
}

@media only screen and (max-width: 1120px) {
  .footerbg {
    height: 9rem;
  }

  .follow_us {
    width: 35%;
  }
  .FooterlastDiv {
    bottom: -14rem;
  }
}

@media only screen and (max-width: 950px) {
  .IEEE_logo {
    width: 10rem;
    height: auto;
  }

  .footerbg {
    height: 8rem;
  }
  .FooterlastDiv {
    bottom: -13rem;
  }
}

@media only screen and (max-width: 870px) {
  .footerbg {
    height: 7rem;
  }

  .follow_us {
    margin: auto;
    align-items: center;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 0%;
  }

  .linkedinLogo,
  .facebookLogo,
  .instagramLogo,
  .youtubeLogo {
    width: 2.5rem;
  }

  .facebookLogo {
    width: 1.5rem;
  }

  .footerHeading1 {
    color: white;
    font-size: 1.2rem;
  }

  .logo {
    width: 25%;
    margin: auto;
    align-items: center;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .IEEE_logo {
    width: 10rem;
    height: auto;
  }
  .FooterlastDiv {
    bottom: -11rem;
  }
}

@media only screen and (max-width: 680px) {
  .follow_us {
    width: 25%;
    margin: auto;
    align-items: center;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-inline: 10%;
  }

  .footerbg {
    height: 9rem;
    /* background-color: #060b28; */
  }
  .FooterlastDiv {
    bottom: -14rem;
  }
}

@media screen and (max-width: 575px) and (min-width: 435px) {
  .linkedinLogo {
    translate: -0.5rem;
  }
}
@media screen and (max-width: 413px) and (min-width: 360px) {
  .linkedinLogo {
    translate: -0.3rem;
  }
}

@media only screen and (max-width: 540px) {
  .IEEE_logo {
    width: 8rem;
    height: auto;
  }
}

@media only screen and (max-width: 450px) {
  .footerHeading1 {
    font-size: 5rem;
  }

  .follow_us {
    width: 50%;
    margin: auto;
    align-items: center;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-inline: 0%;
    margin-inline: 10%;
  }

  .linkedinLogo,
  .facebookLogo,
  .instagramLogo,
  .youtubeLogo {
    width: 2rem;
  }

  .facebookLogo {
    width: 1.5rem;
    translate: 0 -0.25rem;
  }

  .footerHeading1 {
    color: white;
    font-size: 1rem;
  }

  .logo {
    width: 30%;
    margin: auto;
    align-items: center;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .IEEE_logo {
    width: 7rem;
    height: auto;
  }
}

@media only screen and (max-width: 400px) {
  .FooterlastDiv {
    bottom: -13rem;
  }
  .footerCopyrightLine {
    font-size: 0.8rem;
  }
  .footerDevelopersLine {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 360px) {
  .footerbg {
    position: absolute;
    height: 11rem;
  }

  .FooterlastDiv {
    bottom: -15rem;
  }

  .footer1 {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-inline: 2%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .follow_us {
    width: 100%;
    margin: auto;
    align-items: center;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-inline: 0%;
  }

  .logo {
    width: 90%;
    margin: auto;
    align-items: center;
    text-align: center;
    padding: 5%;
  }

  .IEEE_logo {
    width: 7.5rem;
    height: auto;
    padding-top: 5%;
  }
}

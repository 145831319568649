.coreTrial {
  display: flex;
  flex-wrap: wrap;
  /* width: 1480px; */
  width: 100%;
  /* margin-left: 34px; */
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
}

.execomTrial {
  display: flex;
  flex-wrap: wrap;
  /* width: 1480px; */
  /* margin-top: 300px; */
  width: 100%;
  margin-top: 25px;
  /* margin-left: 34px; */
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
}

.trial {
  /* width:1519px; */
  /* height:7350px; */
  width: 100%;
  overflow-x: hidden;
  background: radial-gradient(
    105.9% 15.53% at 49.97% 0%,
    #0e213b 0%,
    rgba(14, 32, 59, 0.997541) 0.01%,
    rgba(14, 32, 58, 0.983713) 16.91%,
    rgba(13, 31, 58, 0.97) 25.09%,
    rgba(13, 32, 58, 0.95) 39.61%,
    rgba(13, 24, 51, 0.9) 55.05%,
    rgba(6, 11, 40, 0.9) 70.49%,
    rgba(6, 11, 40, 0.95) 83.2%,
    rgba(6, 11, 40, 0.97) 91.37%,
    #060b28 100%
  );
  margin-bottom: 10rem;
}

.aboutUsFooter {
  margin-top: 10rem;
}

@media screen and (max-width: 520px) {
  .coreTrial {
    width: 100%;
    margin: auto;
  }

  .execomTrial {
    width: 100%;
    margin: auto;
  }

  .teamimage {
    width: 100vw;
  }

  .heading-1 {
    width: fit-content;
    margin: 3rem auto;
    display: block;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
  }

  /* .desc {
    width: 85vw;
    margin: 1rem auto;
    display: block;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: white;
    text-align: center;
  } */

  .heading-3 {
    width: fit-content;
    margin: 6rem auto;
    display: block;

    font-size: 35px;
    line-height: 40px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
  }

  .core2022-23 {
    /* width: fit-content; */
    margin: 1rem auto;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    font-family: "Inter";
    font-style: normal;
  }

  .execom2022-23 {
    width: fit-content;
    margin: auto;
    margin-top: 6rem;
    display: block;

    font-weight: 400;
    font-size: 20px;
    line-height: 50px;
    font-family: "Inter";
    font-style: normal;
  }

  .execomteam {
    width: 100px;
    /* height: 150px; */
    margin-top: 20px;
    border-radius: 15px;
  }

  .coreteam {
    width: 100px;
    /* height: 150px; */
    margin-top: 20px;
    border-radius: 15px;
  }

  .bgo {
    height: 70%;
    width: 95%;
    background: linear-gradient(180deg, #0f163d 0%, rgba(15, 22, 61, 0) 100%);
    border-radius: 15px;
    margin-left: 2px;
    margin-top: 2px;
  }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 0;
    overflow: hidden;
    width: 70%;
    height: 0;
    border-radius: 15px;
    transition: 0.4s ease-in-out;
    z-index: 2;
  }

  .AUname {
    font-size: 10px;
    margin-top: 10px;
    font-weight: 700;
    line-height: 21px;
  }

  .AUposition {
    font-size: 8px;
    margin-top: 0px;
  }

  /* .images{
        width: 96%;
        height: 72%;
        margin-left:2px;
        margin-top:14px;
    } */

  .images {
    width: 80%;
    aspect-ratio: 1;
    margin-left: 11px;
    margin-top: 14px;
  }

  .execomteam:hover .images {
    border-radius: 15px;
    width: 96%;
    height: 92%;
    margin-left: 2px;
    margin-top: 2px;
    transition: ease-out 0.3s;
  }

  .execomteam:hover .overlay {
    left: 0px;
    height: 100%;
    width: 100px;
    border-radius: 15px;
    z-index: -999;
  }

  .coreteam:hover .images {
    border-radius: 15px;
    width: 96%;
    height: 92%;
    margin-left: 2px;
    margin-top: 2px;
    transition: ease-out 0.3s;
  }

  .coreteam:hover .overlay {
    left: 0px;
    height: 100%;
    width: 100px;
    border-radius: 15px;
    z-index: -999;
  }

  .coreteam:hover .bgo {
    background: #4623ae;
    background: -webkit-linear-gradient(top left, #4623ae, #161cea);
    background: -moz-linear-gradient(top left, #4623ae, #161cea);
    background: linear-gradient(to bottom right, #4623ae, #161cea);
  }

  .execomteam:hover .bgo {
    background: #4623ae;
    background: -webkit-linear-gradient(top left, #4623ae, #161cea);
    background: -moz-linear-gradient(top left, #4623ae, #161cea);
    background: linear-gradient(to bottom right, #4623ae, #161cea);
  }

  .LImage {
    width: 25%;
    height: 25%;
    margin-top: 110px;
    margin-left: 20px;
  }

  .GitImage {
    width: 25%;
    height: 25%;
    margin-top: 110px;
    margin-left: 10px;
  }

  .GitImage:hover {
    transform: scale(0.75, 0.75);
  }

  .LImage:hover {
    transform: scale(0.75, 0.75);
  }
}

@media screen and (max-width: 472px) {
  .trial {
    margin-bottom: 5rem;
  }
  .coreTrial {
    width: 96%;
    gap: 10px;
  }
  .execomTrial {
    width: 96%;
    gap: 10px;
  }
  .aboutUsFooter {
    margin-top: 5rem;
  }
}

@media screen and (max-width: 527px) {
  .coreTrial {
    width: 100%;
    gap: 10px;
  }
  .execomTrial {
    width: 100%;
    gap: 10px;
  }
}

@media screen and (max-width: 800px) {
  .coreTrial {
    width: 100%;
    gap: 10px;
  }
  .execomTrial {
    width: 100%;
    gap: 10px;
  }
}

@media screen and (max-width: 1057px) {
  .coreTrial {
    width: 100%;
    gap: 20px;
    justify-content: space-evenly;
  }
  .execomTrial {
    width: 100%;
    gap: 20px;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 1303px) {
  .coreTrial {
    width: 100%;
    gap: 15px;
    justify-content: space-evenly;
  }
  .execomTrial {
    width: 100%;
    gap: 15px;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 1346px) {
  .coreTrial {
    width: 100%;
    gap: 15px;
    justify-content: space-evenly;
  }
  .execomTrial {
    width: 100%;
    gap: 15px;
    justify-content: space-evenly;
  }
}

.facultyTrail {
  translate: 0 -30%;
}

.facultyTeam {
  height: fit-content;
}

.FacultyName {
  line-height: 26px;
  width: 100%;
  margin: auto;
}

@media screen and (max-width: 520px) {
  .FacultyName {
    width: 80%;
    line-height: 18px;
    margin-top: 1rem;
  }
}

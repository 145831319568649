.loginForm {
  position: relative;
  width: 30%;
  background-color: #060b27;
  border: 5px solid #283e5f;
  /* padding: 3rem 4rem; */
  padding: 3rem 0rem;
  border-radius: 30px;
  z-index: 9;
  margin-top: 4rem;
  margin-bottom: 5rem;
}

.LFtitle {
  margin-bottom: 3rem;
}

.lemail,
.lpassword {
  width: 70%;
}

.lemail input,
.lpassword input {
  font-size: 0.9rem;
}

.LIforgotPassword {
  text-align: right;
  width: 70%;
  float: right;
  color: #868e96;
  margin: 0.8rem 0 0.8rem 0;
  text-decoration: underline;
  font-size: 0.8rem;
}

.LIforgotPassword:hover {
  color: #c1c2c5;
}

.FPtitle {
  font-size: 2rem;
  padding-bottom: 1rem;
}

.FPreset {
  font-size: 1.2rem;
  padding-bottom: 1rem;
  text-align: center;
}

.greenTickContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.greenTick {
  width: 100px;
}

.HPHContainer {
  height: 100vh;
  /* width: fit-content; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.HPHtitle {
  font-size: 6.5em;
  font-weight: bold;
  font-family: "Inter";
  /* font-style: italic; */
  /* transform: skew(-10deg); */
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 76.83%,
    rgba(84, 41, 206, 0) 156.84%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* width: 80%; */
  margin: auto;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.HPtitleBig {
  font-size: 6.5rem;
  margin: 0;
}

.HPtitleSmall {
  font-size: 5rem;
  margin: 0;
  width: 100%;
}

.HPHtitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  font-family: "Inter";
}

@media screen and (max-width: 993px) {
  .HPHtitle {
    font-size: 3.5em;
  }
}

@media screen and (max-width: 628px) {
  .HPHtitle {
    font-size: 3em;
  }
}

.domain {
  height: 100vh;
  /* width: 100vw; */
  overflow-x: hidden;
  overflow: hidden;
  /* background-color: #060b28; */
}

.domainContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-x: hidden;
  overflow: hidden;
}

.DIMcardContainer {
  position: relative;
  color: white;
  width: 60vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background: red; */
}

.DIMcard {
  display: flex;
  width: 60%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: yellow; */
}

.DIMtitle {
  font-weight: 700;
  font-size: 3rem;
  font-family: "Inter";
  font-style: italic;
  /* line-height: 87px; */
  text-align: center;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #559fca 1.87%, #735fe7 75.53%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.DIMtitle2 {
  font-size: 2.5rem;
  font-style: normal;
}

.DIMinfo {
  width: 90%;
  /* display: flex; */
  /* justify-content: center; */
  text-align: justify;
  font-size: 1.2rem;
}

#circleContainer {
  position: relative;
  width: 32vw;
  height: 32vw;
  margin: 10px auto;
  position: relative;
  border-radius: 50%;
  z-index: 2;
  transition: all ease 1s;
  border: 3px solid #735fe7;
  translate: 40%;
  animation: rotation 36s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.item {
  /* width: 18%; */
  width: 7vw;
  /* height: 25%; */
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  z-index: inherit;
  transform: rotateZ(-80deg);
}

.srow1,
.srow2,
.srow3,
.srow4 {
  width: 120%;
  translate: -8%;
  /* width: 25rem; */
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 40%;
}

.srow1 {
  transform: rotateZ(0deg);
}
.srow2 {
  transform: rotateZ(45deg);
}
.srow3 {
  transform: rotateZ(90deg);
}
.srow4 {
  transform: rotateZ(135deg);
}

.ai {
  transform: rotate(0deg);
  translate: -10px;
}

.center {
  width: 10vw;
  height: 10vw;
  /* translate: -20px 0%; */
}

.DIMgifContainer {
  width: 100%;
  height: 100%;
}

.DIMdomainGif {
  width: 25vh;
  height: 25vh;
  translate: -50% 10%;
  position: absolute;
}

.scrollContainer {
  position: relative;
  width: 30vw;
  float: right;
  height: 80vh;
  top: -170vh;
  overflow-x: hidden;
  overflow-y: scroll;
  /* background-color: yellow; */
  z-index: 99;
}

.scrollElement {
  width: 100%;
  height: 800vh;
  z-index: 99;
}

.app {
  transform: rotate(180deg);
}

.blockchain {
  transform: rotate(183deg);
}

.coding {
  transform: rotate(187deg);
}

.cyber {
  transform: rotate(183deg);
}

.iot {
  transform: rotate(0deg);
}

.research {
  transform: rotate(1deg);
}

.web {
  transform: rotate(3deg);
}

.scrollContainer::-webkit-scrollbar {
  display: none;
}

.newPage {
  width: 100%;
  height: 100vh;
  /* background-color: red; */
  overflow-x: hidden;
  /* position: relative; */
  color: white;
  /* top: -280vh; */
}

.hr {
  width: 100%;
  height: 1px;
  background-color: white;
  position: relative;
}

.refElem {
  height: 80vh;
  width: 100vw;
  /* background-color: blue; */
  position: relative;
  top: -91vh;
}

.seeMore {
  position: relative;
  top: -170vh;
  right: -37%;
  height: 80vh;
  width: 30vw;
  padding-top: 0.5rem;
  font-size: 140%;
  display: flex;
  float: right;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  z-index: 0;
}

.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

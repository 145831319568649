/* .Hiding_spline{
    position: absolute;
    top: 100px;
} */

* {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

.frameHolder {
  height: 100vh;
  /* width:100vw; */
  z-index: 1;
  position: relative;
}

.HomeBg {
  /* background-color: rgba(14, 33, 59, 1); */
  background: linear-gradient(
    179.82deg,
    #0e213b -12.5%,
    rgba(14, 32, 59, 0.997541) -12.5%,
    rgba(14, 32, 58, 0.983713) -8.22%,
    rgba(13, 31, 58, 0.97) -6.15%,
    rgba(13, 32, 58, 0.95) -2.48%,
    #0d1833 3.66%,
    rgba(6, 11, 40, 0.98) 5.34%,
    rgba(6, 11, 40, 0.95) 8.55%,
    rgba(6, 11, 40, 0.97) 10.62%,
    #060b28 12.81%
  );

  color: azure;
}

.loading_gif {
  margin-left: 46vw !important ;
  margin-top: 35vh !important;
  width: 10vw !important ;
  height: 10vw !important;
}

.DomainMobile {
  display: none;
}

.splineHidden {
  display: none;
}

@media screen and (max-width: 800px) {
  .DomainLaptop {
    display: none;
  }
  .DomainMobile {
    display: block;
  }
}

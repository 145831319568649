.signUp {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 6rem;
}

.signUpForm {
  position: relative;
  width: 30%;
  background-color: #060b27;
  border: 5px solid #283e5f;
  padding: 2rem 2rem;
  border-radius: 30px;
  z-index: 9;
  margin-top: 4rem;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.SFtitle {
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: fit-content;
  background: linear-gradient(90deg, #559fca 1.87%, #735fe7 75.53%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
}

.SItitle {
  font-size: 0.9rem;
  color: #868e96;
  margin-bottom: 2rem;
}

.signUpInput {
  margin-top: 0.5rem;
}

.signUpInput input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #060b27 inset !important;
  box-shadow: 0 0 0 1000px #060b27 inset !important;
  -webkit-text-fill-color: white !important;
}

.signUpInput input {
  height: 2rem;
  /* width: 95%; */
  width: 95%;
  margin: 5px auto;
  padding: 0px 0.5rem;
  font-size: 0.8rem;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border: 1px solid #868e96;
  border-radius: 10px;
  background-color: #060b27;
  color: white;
}

.signUpInput input:focus {
  box-shadow: 0 0 0 2px #f8f9fa;
}

.SUname {
  width: 100%;
  margin: auto;
}

.SUemail,
.SUphoneNumber,
.SUpassword,
.SUconfirmPassword {
  width: 47%;
}

.SUphoneNumber input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.member {
  font-size: 1rem;
  text-align: center;
  color: #909296;
  margin-top: 1rem;
  cursor: default;
}

.SUlink {
  display: inline-block;
  color: #c1c2c5;
  text-decoration: underline;
  background-color: #060b27;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 0.9rem;
}

.submitContainer {
  width: 100%;
  display: flex;
  margin-top: 0.5rem;
}

.submit {
  border: none;
  background: none;
  color: white;
  margin: auto;
  width: 161px;
  height: 39px;
  font-weight: 600;
  font-size: 14px;
  background: linear-gradient(90deg, #56b1e3 0%, #4c14bc 100%);
  border-radius: 5px;
  margin-top: 0.5rem;
  cursor: pointer;
}

.forgotPassword {
  display: block;
  text-align: right;
  color: #868e96;
  margin-bottom: 1rem;
  cursor: pointer;
}

.SUflexContainer {
  display: flex;
  justify-content: space-between;
  width: 98%;
  align-self: start;
  /* margin: auto; */
  /* background-color: red; */
}

.selectYearDiv {
  width: 50%;
  margin: auto;
  /* background-color: red; */
  margin-top: 0.5rem;
}

.selectYear {
  height: 2rem;
  width: 100%;
  margin: 5px auto;
  padding: 0px 0.3rem;
  font-size: 14px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border: 1px solid #868e96;
  border-radius: 10px;
  background-color: #060b27;
  color: white;
}

.ieeeMemberDiv {
  /* width: 70%; */
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ieeeMemberDiv input {
  width: 15px;
  height: 15px;
  border-radius: 4px;
  margin: 0px;
  outline: none;
  border: none;
  box-shadow: none;
}

.ieeeMemberLabel {
  translate: 0 100%;
  margin-left: 10px;
  width: fit-content;
}

.ieeeMemberDiv input:focus {
  box-shadow: 0 0 0 0px #f8f9fa;
}

select:required:invalid {
  color: gray;
}
select,
option {
  color: white;
}

.otp {
  text-align: center;
  width: 100%;
  word-wrap: break-word;
  margin: 0rem 0 0.8rem 0;
}

.otpError {
  margin: 0.8rem 0 0rem 0;
}

.myFileContainer {
  text-align: center;
}

/* Style for input[type="file"] */
input[type="file"] {
  display: none;
}

/* Style for custom file input */
.custom-file-input {
  background-color: #060b27;
  border: 1px solid #868e96;
  border-radius: 5px;
  color: #868e96;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: 400;
  margin: 5px 0;
  padding: 8px 15px;
  transition: all 0.4s ease-in-out;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin-bottom: 10px;
}

/* Style for custom file input on hover */
.custom-file-input:hover {
  background-color: #eee;
  border: 1px solid #aaa;
  color: #222;
}

/* Style for custom file input when it has a file selected */
.custom-file-input.has-file {
  background-color: #fff;
  border: 1px solid #aaa;
  color: #222;
}

/* Style for the label of the custom file input */
.custom-file-input-label {
  display: inline-block;
  margin-right: 10px;
}

/* Style for the name of the selected file */
.selected-file-name {
  display: inline-block;
  font-size: 0.9em;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  color: white;
  border-left: 2px solid #868e96;
  padding-left: 0.8rem;
}

.custom-file-input:hover .selected-file-name {
  color: #222;
}

.hide {
  display: none;
}

.timer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.timerCounter {
  margin: 0.8rem;
}

.verifyContainer {
  font-size: 0.8rem;
  text-align: center;
  color: #909296;
  margin-top: 0.7rem;
  cursor: default;
}

.verifyEmail {
  font-size: 0.8rem;
}

.otpLink:disabled {
  cursor: not-allowed;
  color: #868e96;
}

.my-toast {
  white-space: pre-wrap;
  z-index: 999;
}

.my-toast-body {
  white-space: pre-wrap;
  z-index: 999;
}

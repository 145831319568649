@media screen and (max-width: 1024px) {
    .signUpForm {
      width: 40%;
    }
  }
  
  @media screen and (max-width: 750px) {
    .SUflexContainer {
      flex-direction: column;
      width: 100%;
    }
    .SUname,
    .SUemail,
    .SUphoneNumber {
      width: 100%;
    }
  }
  
  @media screen and (max-height: 600px) {
    .gradContainer1,
    .gradContainer2 {
      height: 106vh;
    }
  }
  
  @media screen and (max-height: 480px) {
    .gradContainer1,
    .gradContainer2 {
      height: 116vh;
    }
  
    .signUpForm {
      width: 50%;
    }
  }
  
  @media screen and (max-width: 585px) {
    .signUpForm {
      width: 70%;
    }
    .SFtitle {
      font-size: 2rem;
    }
  
    div.signUpForm {
      padding: 2rem 2rem;
      width: 70%;
      margin-bottom: 0rem;
    }
  }
  
  @media screen and (max-width: 320px) {
    .SItitle {
      font-size: 0.8rem;
    }
  }
  
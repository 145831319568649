/* Main container styling */
.hackathonSignUp {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-bottom: 6rem;
  }
  
  .hackathonSignUpForm {
    position: relative;
    width: 30%;
    background-color: #060b27;
    border: 5px solid #283e5f;
    padding: 2rem 2rem;
    border-radius: 30px;
    z-index: 9;
    margin-top: 4rem;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .HFtitle {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: fit-content;
    background: linear-gradient(90deg, #559fca 1.87%, #735fe7 75.53%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: center;
  }
  
  .HSItitle {
    font-size: 0.9rem;
    color: #868e96;
    margin-bottom: 2rem;
  }
  
  .hackathonInput {
    margin-top: 0.5rem;
  }
  
  .hackathonInput input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px #060b27 inset !important;
    box-shadow: 0 0 0 1000px #060b27 inset !important;
    -webkit-text-fill-color: white !important;
  }
  
  .hackathonInput input {
    height: 2rem;
    width: 95%;
    margin: 5px auto;
    padding: 0px 0.5rem;
    font-size: 0.8rem;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border: 1px solid #868e96;
    border-radius: 10px;
    background-color: #060b27;
    color: white;
  }
  
  .hackathonInput input:focus {
    box-shadow: 0 0 0 2px #f8f9fa;
  }
  
  .HUname {
    width: 100%;
    margin: auto;
  }
  
  .HUemail,
  .HUphoneNumber {
    width: 47%;
  }
  
  .HUphoneNumber input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  
  .submitContainer {
    width: 100%;
    display: flex;
    margin-top: 0.5rem;
  }
  
  .submit {
    border: none;
    background: none;
    color: white;
    margin: auto;
    width: 161px;
    height: 39px;
    font-weight: 600;
    font-size: 14px;
    background: linear-gradient(90deg, #56b1e3 0%, #4c14bc 100%);
    border-radius: 5px;
    margin-top: 0.5rem;
    cursor: pointer;
  }
  
  .HUflexContainer {
    display: flex;
    justify-content: space-between;
    width: 98%;
    align-self: start;
  }
  
  /* Dropdown for Year and Participation Category */
  .selectYearDiv,
  .selectCategoryDiv {
    width: 47%;
  }
  
  .selectYear,
  .selectCategory {
    height: 2rem;
    width: 100%;
    margin: 5px auto;
    padding: 0px 0.3rem;
    font-size: 14px;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border: 1px solid #868e96;
    border-radius: 10px;
    background-color: #060b27;
    color: white;
  }
  
  select:required:invalid {
    color: gray;
  }
  select,
  option {
    color: white;
  }

  /* Dropdown for College */
.selectCollegeDiv {
  width: 47%;
}

.selectCollege {
  height: 2rem;
  width: 100%;
  margin: 5px auto;
  padding: 0px 0.3rem;
  font-size: 14px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border: 1px solid #868e96;
  border-radius: 10px;
  background-color: #060b27;
  color: white;
}

select:required:invalid {
  color: gray;
}

select,
option {
  color: white;
}

  
  /* How Did You Hear About Hackathon? Field */
  .hearAboutHackathonDiv {
    width: 100%;
    margin-top: 1rem;
  }
  
  .hearAboutHackathon {
    width: 95%;
    height: 4rem;
    padding: 0.5rem;
    border: 1px solid #868e96;
    border-radius: 10px;
    background-color: #060b27;
    color: white;
    font-size: 0.9rem;
  }
  
  .SUflexContainer {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  .signUpInput {
    flex: 1;
    min-width: 250px; /* Ensures proper wrapping */
  }
  
  .teamMembersContainer h3 {
    margin-top: 1rem;
  }
  
  .addMemberBtn,
  .removeMemberBtn {
    background: #ff5c5c;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    height: 2rem;
  }
  .removeMemberBtn{
    margin-top: 35px;
  }
  
  .addMemberBtn {
    background: linear-gradient(90deg, #56b1e3 0%, #4c14bc 100%);
    border-radius: 4px ;
    margin-top: 1rem;
  }
  
  .submit {
    width: 100%;
    background: linear-gradient(90deg, #56b1e3 0%, #4c14bc 100%);
    color: white;
    padding: 12px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  /* === College & Branch Fields Styling === */
  .collegeBranchContainer {
    display: flex;
    justify-content: space-between;
    width: 98%;
    align-self: start;
  }
  
  .collegeBranchContainer .signUpInput {
    width: 50%;
  }
  
  /* Responsive Fixes */
  @media screen and (max-width: 768px) {
    .hackathonSignUpForm {
      width: 90%;
    }
  
    .HUflexContainer,
    .collegeBranchContainer {
      flex-direction: column;
    }
  
    .HUemail,
    .HUphoneNumber,
    .collegeBranchContainer .signUpInput {
      width: 100%;
    }
  }
  
/* Style for all textareas */
.hackathonInput textarea,
.signUpInput textarea {
  width: 95% !important;  /* Ensures full width */
  height: 4rem; /* Adjust height */
  padding: 0.5rem;
  border: 1px solid #868e96;
  border-radius: 10px;
  background-color: #060b27;
  color: white;
  font-size: 0.9rem;
  resize: none; /* Prevent resizing */
}

.hackathonInput textarea:focus,
.signUpInput textarea:focus {
  box-shadow: 0 0 0 2px #f8f9fa;
}

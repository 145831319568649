@import url("https://fonts.googleapis.com/css?family=Bad+Script");

.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: "Bad Script", cursive;
  font-size: 16px;
  background: #f2f2f2;
}

.wrap-404 {
  overflow: hidden;
  padding: 2rem 1rem;
}

.EPlabel {
  text-align: center;
  font-size: 0.75em;
}

.EPnumbers {
  padding: 2rem 0 0;
}

.EPnumber {
  position: relative;
  display: inline-block;
  width: 150px;
  transform: translateX(60%);
}

.EPfour {
  position: relative;
  width: 30px;
  height: 150px;
  border: 1px solid #000;
}
.EPfour::before,
.EPfour::after {
  content: "";
  position: absolute;
  border: 1px solid #000;
}
.EPfour::before {
  top: 50%;
  right: -1rem;
  width: 120px;
  height: 30px;
}
.EPfour::after {
  top: 0;
  left: -60px;
  width: 30px;
  height: 120px;
}

.EPzero {
  position: relative;
  width: 30px;
  height: 150px;
  border: 1px solid #000;
}
.EPzero .EPspan {
  position: absolute;
  top: 0;
  left: -60px;
  width: 30px;
  height: 150px;
  border: 1px solid #000;
}
.EPzero::before,
.EPzero::after {
  content: "";
  position: absolute;
  border: 1px solid #000;
}
.EPzero::before {
  top: 1rem;
  right: -1rem;
  width: 120px;
  height: 30px;
}
.EPzero::after {
  bottom: 1rem;
  right: -1rem;
  width: 120px;
  height: 30px;
}

.EPtext {
  position: relative;
  padding-top: 6rem;
  text-align: center;
  font-size: 1.125em;
}
.EPtext .EPa {
  color: #000;
}
.EPtext::before {
  content: "";
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  width: 6rem;
  height: 1px;
  background-image: repeating-linear-gradient(
    to left,
    rgba(0, 0, 0, 0.35) 0,
    rgba(0, 0, 0, 0.35) 0.5rem,
    transparent 0.5rem,
    transparent 1rem
  );
}

.EPsleep-walker {
  position: relative;
  height: 1px;
  margin: 5rem 0 3rem;
  background-image: repeating-linear-gradient(
    to left,
    transparent 0,
    transparent 0.12rem,
    rgba(0, 0, 0, 0.15) 0.125rem,
    rgba(0, 0, 0, 0.15) 0.25rem
  );
}

.EPman {
  position: absolute;
  top: -1.4rem;
  left: 0;
  width: 1px;
  height: 2rem;
  opacity: 0.6;
  transform: scale(0.5);
  animation: walking 50s linear infinite;
}
.EPman .EPhead {
  position: relative;
  width: 0.5rem;
  height: 0.5rem;
  transform: translateX(-1px);
  border: 1px solid #000;
  border-radius: 50%;
}
.EPman .EPhead::before {
  content: "";
  position: absolute;
  top: 0.28rem;
  left: 0;
  width: 170%;
  height: 1px;
  background: #000;
  transform-origin: 0% 0%;
  transform: rotate(-25deg);
}
.EPman .EPtorso {
  position: relative;
  width: 1px;
  height: 50%;
  margin: 0 auto;
  background: #000;
}
.EPman .EPtorso .EParm-a,
.EPman .EPtorso .EParm-b {
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
  height: 85%;
  transform-origin: top center;
  background: #000;
}
.EPman .EPtorso .EParm-a::before,
.EPman .EPtorso .EParm-b::before {
  content: "";
  position: absolute;
  bottom: -0.1rem;
  left: 0rem;
  width: 0.18rem;
  height: 0.18rem;
  border: 1px solid #000;
  border-radius: 50%;
}
.EPman .EPtorso .EParm-a {
  transform: rotate(-20deg);
  animation: walk1 1.3s linear alternate infinite;
}
.EPman .EPtorso .EParm-b {
  transform: rotate(20deg);
  animation: walk2 1.3s linear alternate infinite;
}
.EPman .EPlegs {
  position: relative;
  width: 1px;
  height: 50%;
  margin: 0 auto;
}
.EPman .EPlegs .EPleg-a,
.EPman .EPlegs .EPleg-b {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: top center;
  background: #000;
}
.EPman .EPlegs .EPleg-a::before,
.EPman .EPlegs .EPleg-b::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 4px;
  height: 1px;
  background: #000;
}
.EPman .EPlegs .EPleg-a {
  transform: rotate(-20deg);
  animation: walk1 1.3s linear alternate infinite;
}
.EPman .EPlegs .EPleg-b {
  transform: rotate(20deg);
  animation: walk2 1.3s linear alternate infinite;
}

@keyframes walking {
  0% {
    left: 0;
    transform: scale(0.5) rotateY(0deg);
  }
  49.9% {
    transform: scale(0.5) rotateY(0deg);
  }
  50% {
    transform: scale(0.5) rotateY(180deg);
    left: 100%;
  }
  100% {
    transform: scale(0.5) rotateY(180deg);
    left: 0;
  }
}
@keyframes walk1 {
  0% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(20deg);
  }
}
@keyframes walk2 {
  0% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(-20deg);
  }
}
@media all and (max-width: 768px) {
  .EPheader .EPh1 {
    margin-bottom: 3rem;
  }

  .EPman {
    animation-duration: 30s;
  }
}
@media all and (max-width: 768px) {
  .EPnumbers {
    display: flex;
    justify-content: center;
  }

  .EPnumber {
    width: 90px;
    transform: translateX(36%) scale(0.5);
  }
  .errorImage {
    width: 80%;
  }
}

.ieeepresents {
    background: linear-gradient(
    90.38deg,
    rgba(140, 215, 255, 0.9) 13.54%,
    rgba(84, 41, 206, 0.9) 71.44%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.button{
    border: none;
    background: none;
    color: white;
    margin: auto;
    width: 161px;
    height: 39px;
    font-weight: 600;
    font-size: 14px;
    background: linear-gradient(90deg, #56b1e3 0%, #4c14bc 100%);
    border-radius: 5px;
    margin-top: 0.5rem;
    cursor: pointer;
}

.button:hover {
    background-color: #0056b3;
  }

/* Mobile-first Media Queries */
@media screen and (max-width: 768px) {
    /* Previous styles remain the same */
    
    /* Updated Event Card Styles */
    motion.div[style*="backgroundColor: #060b27"] {
      width: 100% !important;
      margin: 10px auto;
      padding: 15px !important;
      max-width: 340px; /* Added max-width for Samsung phones */
    }
  
    motion.div[style*="backgroundColor: #060b27"] h2 {
      font-size: 1.5rem !important;
      margin-bottom: 8px !important;
    }
  
    motion.div[style*="backgroundColor: #060b27"] p {
      font-size: 0.9rem !important;
      line-height: 1.4 !important;
      margin: 8px 0 !important;
    }
  
    /* Event Cards Container */
    motion.div[style*="display: flex"][style*="justifyContent: center"] {
      padding: 20px 10px !important;
      gap: 15px !important;
    }
  }
  
  /* Samsung/Android specific breakpoint */
  @media screen and (max-width: 412px) {
    /* Event Cards Specific Adjustments */
    motion.div[style*="backgroundColor: #060b27"] {
      width: 90% !important;
      margin: 8px auto;
      padding: 12px !important;
    }
  
    motion.div[style*="backgroundColor: #060b27"] h2 {
      font-size: 1.3rem !important;
    }
  
    motion.div[style*="backgroundColor: #060b27"] p {
      font-size: 0.85rem !important;
    }
  
    /* Button Adjustments */
    motion.div[style*="backgroundColor: #060b27"] button {
      padding: 8px 16px !important;
      font-size: 0.9rem !important;
    }
  
    /* Container Spacing */
    motion.div[style*="display: flex"][style*="justifyContent: center"] {
      padding: 15px 5px !important;
      margin-bottom: 50px !important;
    }
  }
  
  /* Extra Small Devices */
  @media screen and (max-width: 360px) {
    motion.div[style*="backgroundColor: #060b27"] {
      width: 95% !important;
      padding: 10px !important;
    }
  
    motion.div[style*="backgroundColor: #060b27"] h2 {
      font-size: 1.2rem !important;
    }
  
    motion.div[style*="backgroundColor: #060b27"] p {
      font-size: 0.8rem !important;
      margin: 6px 0 !important;
    }
  }
  
  /* Preserve previous media queries... */
  /* Specific styles for screens under 400px */
@media screen and (max-width: 400px) {
    /* Text Container Adjustments */
    .ieeepresents {
      padding: 30px 10px !important;
    }
  
    .ieeepresents div:first-child {
      font-size: 1.2rem !important;
    }
  
    .ieeepresents div:nth-child(2) {
      font-size: 2.2rem !important;
      margin: 5px 0 !important;
    }
  
    .ieeepresents div:nth-child(3) {
      font-size: 1.5rem !important;
    }
  
    /* Container Adjustments */
    .containerTs {
      padding: 5px;
    }
  
    /* Event Cards */
    motion.div[style*="backgroundColor: #060b27"] {
      width: 95% !important;
      max-width: 350px !important;
      padding: 12px !important;
      margin: 8px auto !important;
    }
  
    motion.div[style*="backgroundColor: #060b27"] h2 {
      font-size: 1.3rem !important;
      margin-bottom: 6px !important;
    }
  
    motion.div[style*="backgroundColor: #060b27"] p {
      font-size: 0.85rem !important;
      line-height: 1.3 !important;
      margin: 6px 0 !important;
      padding: 0 5px !important;
    }
  
    motion.div[style*="backgroundColor: #060b27"] button {
      padding: 8px 16px !important;
      font-size: 0.9rem !important;
      margin-top: 10px !important;
    }
  
    /* Tech Summit Session Adjustments */
    .TechsummitSession1Container {
      padding: 15px 8px;
    }
  
    .TechsummitSession1Heading {
      font-size: 1.8rem;
    }
  
    .TechsummitSession1SubHeading ul {
      padding-left: 15px;
    }
  
    .TechsummitSession1SubHeading li {
      font-size: 0.85rem;
      margin-bottom: 8px;
      line-height: 1.4;
    }
  
    /* Sponsors Section */
    .heading5 {
      font-size: 1.8rem;
    }
  
    .subDetailsSponsers {
      font-size: 0.85rem;
      padding: 0 10px;
    }
  
    /* Venue Section */
    .heading6 {
      font-size: 1.8rem;
    }
  
    .mapsImage iframe {
      height: 250px !important;
    }
  
    .collegeAddress {
      font-size: 0.85rem;
      padding: 12px 10px;
    }
  
    /* Logo Adjustments */
    .CSILogo, .uptiqLogo {
      max-width: 75%;
    }
  
    /* Line Separator */
    .line {
      margin: 25px auto;
    }
  
    /* Events Section Container */
    motion.div[style*="display: flex"][style*="justifyContent: center"] {
      padding: 15px 5px !important;
      margin-bottom: 40px !important;
      gap: 12px !important;
    }
  
    /* Button Styles */
    .button {
      padding: 8px 16px !important;
      font-size: 0.9rem !important;
    }
  
    /* Additional Spacing Adjustments */
    .TSWflexConainer2 {
      margin-bottom: 15px;
    }
  
    .uptiqPresents {
      font-size: 1.2rem;
      margin: 8px 0;
    }
  }
@media screen and (max-width: 1280px) {
  .seeMore {
    font-size: 1.5rem;
    right: -38%;
  }
}

@media screen and (max-width: 1024px) {
  div.seeMore {
    font-size: 1rem;
    top: -170vh;
    right: -38%;
  }
  h1.DIMtitle {
    font-size: 2.5rem;
    width: 52vw;
  }
  h1.DIMtitle2 {
    font-size: 2.2rem;
  }
  p.DIMinfo {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
}

@media screen and (max-width: 800px) {
  h1.DIMtitle {
    font-size: 2.5rem;
    width: 52vw;
  }
  div.seeMore {
    font-size: 0.8rem;
    top: -170vh;
    right: -37%;
  }
  p.DIMinfo {
    width: 40vw;
  }
  div#circleContainer {
    width: 40vw;
    height: 40vw;
  }
}

@media screen and (max-width: 600px) {
  .DIMcard {
    width: 90%;
  }
  .domainContainer {
    width: 140vw;
  }

  h1.DIMtitle {
    font-size: 1.5rem;
    padding-left: 1rem;
  }
  .DIMinfo {
    width: 100%;
    padding-left: 1rem;
    font-size: 0.7rem;
  }
  .DIMdomainGif {
    translate: 0% 10%;
  }

  #circleContainer {
    width: 50vw;
    height: 50vw;
    translate: -10%;
  }
  .item {
    width: 13vw;
    height: 13vw;
  }

  .web {
    translate: -30%;
  }

  .center {
    width: 15vw;
    height: 15vw;
  }

  .iot {
    translate: -20%;
  }

  .center1 {
    translate: -30% -20%;
  }

  .center2 {
    translate: 18%;
  }
  .center7 {
    translate: -28% 15%;
  }
  .center5 {
    translate: -20% 10%;
  }
  .research {
    translate: -20%;
  }
  .scrollElement {
    height: 400vh;
  }
  .seeMore {
    font-size: 0.5rem;
    top: -172vh;
    right: -38%;
  }
  .arrow {
    border-width: 0 2px 2px 0;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
  div.seeMore {
    font-size: 0.4rem;
    top: -172vh;
    right: -38%;
  }
}

@media screen and (max-width: 480px) {
  p.DIMinfo {
    font-size: 0.8rem;
    width: 150%;
  }
  div.seeMore {
    font-size: 0.6rem;
    top: -171vh;
    right: -30%;
    width: 20vw;
  }
  div.arrow {
    border-width: 0 1px 1px 0;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }
  div#circleContainer {
    width: 50vw;
    height: 50vw;
    translate: -10%;
  }
  img.item {
    width: 13vw;
    height: 13vw;
  }
  img.DIMdomainGif {
    translate: -20% 20%;
  }
}

.userProfile {
  background-color: #060b28;
  /* height: 100vh; */
  /* width: 100vw; */
  overflow-x: hidden;
  color: white;
  padding-bottom: 7rem;
}

.gradientContainer {
  width: 100vw;
  height: 9vh;
}

.gradientBar {
  width: 100%;
  height: 100%;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
}

.profileImageContainer {
  background-color: #c4c4c4;
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
}

.profileImage {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.flexContainer1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  justify-content: center;
  text-align: center;
}

.userFullName {
  font-weight: bold;
  width: 100%;
  font-size: 18px;
  margin-top: 10%;
  margin-bottom: 2%;
}

.smallEmail {
  font-size: 12px;
}

.flexContainer2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: fit-content;
}

.UPbtn {
  padding: 10px 22px;
  background: #25262b;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 0.8rem;
  color: white;
  outline: none;
  border: 2px solid #767986;
  margin-top: 0.8rem;
  width: fit-content;
}

.UPbtn:hover {
  background: #1a1a1a;
  cursor: pointer;
}

.userDetailsContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 40px;
  gap: 27px;
  width: 50%;
  height: fit-content;
  background: #060b28;
  border: 1px solid #767986;
  border-radius: 17px;
  margin: 0 auto;
  margin-top: 7rem;
}

.yourProfile {
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  color: #fff;
}

.userDetailsContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 20px;
  letter-spacing: 0.5px;
  font-size: 0.9rem;
}

.UDtitle {
  display: inline-block;
  color: #868e96;
}

.eventTitle {
  width: 7rem;
}

.event {
  width: 10rem;
}

.eventTextArea {
  display: inline;
  color: #fff;
  border: none;
  border-bottom: 2px solid #060b28;
  outline: none;
  background: #060b28;
  font-size: 0.9rem;
  letter-spacing: 0.03rem;
  margin-left: 3px;
  width: 15vw;
  min-width: 2rem;
  max-width: 100%;
  line-height: 1.6rem;
  height: 10vh;
  resize: none;
}

.UDinput {
  display: inline-block;
  color: #fff;
  border: none;
  border-bottom: 2px solid #060b28;
  outline: none;
  background: #060b28;
  font-size: 0.9rem;
  letter-spacing: 0.03rem;
  margin-left: 3px;
  width: 90%;
  overflow: hidden;
  min-width: 2rem;
  max-width: 20rem;
  line-height: 1.6rem;
  height: fit-content;
  width: 80%;
}

.edit {
  text-decoration: 1.5px underline;
  text-underline-offset: 6px;
}

.UDYear {
  background-color: #000;
  border: none;
  text-decoration: 1.5px underline;
  text-underline-offset: 6px;
  outline: none;
  margin-left: 3px;
  font-weight: 600;
}

.UDYear option {
  padding: 2rem;
}

.UDYear:disabled {
  appearance: none;
  opacity: 1;
}

.UDEventcontainer {
  display: flex;
  flex-wrap: wrap;
}

.UDcontainer {
  margin-bottom: 1rem;
}

.UDphoneNumber {
  width: 10rem;
}

.UDInput2 {
  display: inline;
  margin-left: 3px;
}

.UDMembershipStatus {
  margin-bottom: 0px;
}

.UPlogoutBtnContainer {
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: fit-content;
}

.UPlogoutBtn {
  color: red;
  border: 2px solid red;
  font-size: 14px;
}

.UPlogoutBtn:hover {
  background-color: red;
  color: white;
}

.UDInput2Link {
  color: white;
  text-decoration: none;
}

.UDInput2Link:hover {
  text-decoration: 1px solid underline;
}

.HPTScardContainer {
  min-height: 10vh;
  width: 80%;
  border: 5px solid;
  border-radius: 20px;
  margin: auto;
  /* background-color: red; */
  border-color: rgba(27, 37, 87, 0.8);
  padding: 2rem 0;
  position: relative;
  margin-bottom: 3rem;
}

.HPTScard {
  width: 100%;
  margin: auto;
}

.HPTScardContent1 {
  font-size: 1.5rem;
  text-align: center;
}

.HPTSp1 {
  /* text-align: right; */
  translate: 10%;
}
.HPTSp2 {
  /* text-align: left; */
  translate: -10%;
}

.HPTScardTitle {
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  margin-right: 5rem;
}

.HPTSb1 {
  font-style: italic;
  font-size: 1.7rem;
  background: linear-gradient(
    90deg,
    #8cd7ff 0%,
    #5f4cd8 106.83%,
    rgba(84, 41, 206, 0) 106.84%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.HPTSh1 {
  margin: 0;
  text-shadow: 4px 4px #5f4cd8;
}

.HTPScardTitlep {
  margin: 0;
}

.HPTSflexContainer {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  margin: auto;
}

.HPTSimgContainer {
  width: 24%;
}

.HPTSimg {
  width: 100%;
  border-radius: 20px;
}

.HPTSbtnContainer {
  margin-bottom: 2rem;
}

@media screen and (max-width: 1144px) {
  .HPTSb1 {
    font-size: 1.5rem;
  }
  .HPTScardTitle {
    font-size: 1.5rem;
    margin-right: 2rem;
  }
  .HPTScardContent1 {
    font-size: 1.2rem;
  }
  .HPTSimgContainer {
    width: 30%;
  }
  .HPTSbtnContainer {
    margin-bottom: 1rem;
  }
  .HPTSbtn {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 930px) {
  .HPTScardContainer {
    min-height: 10vh;
    width: 90%;
  }
}

@media screen and (max-width: 820px) {
  .HPTScardContainer {
    min-height: 10vh;
    width: 90%;
    padding: 1rem;
  }
  /* .HPTSb1 {
    font-size: 1.2rem;
  }
  .HPTScardTitle {
    font-size: 1.2rem;
    margin-right: 1rem;
  }
  .HPTScardContent1 {
    font-size: 1rem;
  }
  .HPTSimgContainer {
    width: 30%;
  }
  .HPTSbtnContainer {
    margin-bottom: 1rem;
  }
  .HPTSbtn {
    font-size: 1rem;
  } */
  .HPTSp1 {
    word-wrap: break-word;
    translate: 0%;
    width: fit-content;
    margin: 1rem auto;
  }
  .HPTSp2 {
    translate: -0%;
    width: fit-content;
    word-wrap: break-word;
    margin: 1rem auto;
  }
  .HPTScardContent2 {
    margin-top: 5rem;
  }
  .HPTScardContent3 {
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 600px) {
  .HPTScardTitle {
    font-size: 1.2rem;
  }
  .HPTSb1 {
    font-size: 1.2rem;
  }
  .HPTSbtn {
    font-size: 1rem;
  }
  .HPTScardContent1 {
    font-size: 1rem;
  }
  .HPTSimgContainer {
    width: 40%;
  }
  .HPTScardContainer {
    min-height: 10vh;
    width: 90%;
    padding: 0.5rem;
  }
  .HPTScardContent2 {
    margin-top: 3rem;
  }
  .HPTScardContent3 {
    margin-bottom: 3rem;
  }
  .HPTSbtnContainer {
    margin-bottom: 1rem !important;
  }
  .HPTScardContainer {
    min-height: 10vh;
    margin-top: 5rem;
  }
}
@media screen and (max-width: 360px) {
  .HPTSb1 {
    font-size: 1rem;
  }
  .HPTScardTitle {
    font-size: 1rem;
  }
  .HPTScardContent1 {
    font-size: 0.8rem;
  }
  .HPTSimgContainer {
    width: 50%;
  }
  .HPTSbtn {
    font-size: 0.8rem;
  }
  .HPTSp1 {
    margin: 0.4rem auto;
  }
  .HPTSp2 {
    margin: 0.4rem auto;
  }
  .HTPScardTitlep {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  .userDetailsContainer {
    width: 80%;
  }
  .flexContainer1 {
    width: 100%;
  }
  .flexContainer2 {
    width: 20rem;
  }
}

@media screen and (max-width: 415px) {
  .yourProfile {
    font-size: 1.3rem;
    text-align: center;
  }
  .profileImageContainer {
    width: 4rem;
    height: 4rem;
  }
  .userFullName {
    font-size: 1rem;
  }
  .userDetailsContainer {
    width: 95%;
  }
  .smallEmail,
  .UDtitle,
  .UDinput,
  .UDEmail,
  .UDinput2,
  .event {
    font-size: 0.8rem;
  }
  .UDcontainer {
    width: 60vw;
  }
  .UDform {
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    word-wrap: break-word;
  }
  .smallEmail {
    width: 80vw;
    display: inline-block;
    word-break: break-word;
    margin-bottom: 1rem;
  }

  .UDname {
    width: 100%;
    word-wrap: break-word;
  }
  .UDphoneNo {
    width: 55%;
  }
}

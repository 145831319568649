.Page {
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 5rem;
  margin-top: 3rem;
  margin-bottom: 10rem;
}

.activities {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-size: 4rem;
  text-align: left;
  margin-left: 10vw;
  background: linear-gradient(90deg, #559fca 1.87%, #735fe7 75.53%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-top: 10vw;
}

.row_div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.row_div2 {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.col_div {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.activities_gif {
  width: 4vw;
  height: 4vw;
  margin-left: 1vw;
  border: none;
}

.research_gif {
  margin-top: 6vw;
  width: 90%;
}

.gif_div {
  width: 30%;
}

.codingBoy_gif {
  margin-top: 13vw;
  margin-left: 4vw;
  width: 70%;
  height: 20vw;
}

.blogs_gif {
  margin-top: 10vw;
  margin-left: 0vw;
  width: 100%;
}

.gif_div_2 {
  width: 15%;
}

.coding_boy_gif {
  margin-top: 13vw;
  margin-left: 5vw;
  width: 80%;
  height: 20vw;
}

.team_gif {
  margin-top: 13vw;
  width: 100%;
}

.para {
  text-align: justify;
  color: whitesmoke;
  margin: 5vw;
  /* font-size: 1.8vw; */
  font-size: 1.3rem;
}

.para_box {
  box-sizing: border-box;
  box-shadow: 2rem 2rem 98rem -1rem rgba(111, 203, 253, 0.44);
  border: 3px linear-gradient(180deg, #5a69b0 0%, #5a6e89 100%);
  border-radius: 2rem;
  height: fit-content;
  margin-top: 6vw;
}

.youtube_box {
  box-sizing: border-box;
  box-shadow: 2rem 2rem 98rem -1rem rgba(111, 203, 253, 0.44);
  border: 3px linear-gradient(180deg, #5a69b0 0%, #5a6e89 100%);
  border-radius: 2rem;
  width: 60%;
  height: 30vw;
  margin-top: 6vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  margin-top: 7vw;
  background: linear-gradient(
    88.51deg,
    #92dcfb 8.64%,
    rgba(181, 87, 214, 0.75) 98.39%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 4vw;
  text-align: center;
}

.title_blog {
  margin-top: 7vw;
  margin-bottom: 4vw;
  background: linear-gradient(
    88.51deg,
    #92dcfb 8.64%,
    rgba(181, 87, 214, 0.75) 98.39%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 4vw;
  text-align: center;
}

.title1 {
  margin-top: 7vw;
  background: linear-gradient(
    88.51deg,
    #92dcfb 8.64%,
    rgba(181, 87, 214, 0.75) 98.39%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 3.5rem;
  text-align: center;
}

.youtube_box_iframe {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1020px) {
  .Page {
    margin-bottom: 7rem;
  }
  .activities {
    font-size: 3.5rem;
  }
  .title1 {
    font-size: 3rem;
  }
  .para {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 760px) {
  .activities {
    font-size: 3rem;
  }
  .title1 {
    font-size: 2.5rem;
  }
  .para {
    font-size: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .activities {
    font-size: 2.5rem;
  }
  .activities_gif {
    width: 6vw;
    height: 6vw;
  }
  .title1 {
    font-size: 2rem;
  }
  .research_gif {
    width: 120%;
  }
  .para {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 460px) {
  .activities {
    font-size: 2rem;
  }
  .activities_gif {
    width: 6vw;
    height: 6vw;
  }
  .title1 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 400px) {
  .Page {
    margin-bottom: 3rem;
  }
}
